import { PropsWithChildren, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { MailType } from "types/Mail";

type PropsTextEmailModal = PropsWithChildren<{
  modalOn: boolean;
  toRemoveOn: boolean;
  handleClose: () => void;
  mail: MailType;
}>;

type ButtonMailtoPropsType = MailType & {
  label: string;
};

const ButtonMailto = ({ to, subject, body, label }: ButtonMailtoPropsType) => {
  const mailto = `mailto:${to}?subject=${subject}&body=${body}`;

  return (
    <Button
      style={{ padding: "5px", margin: "10px" }}
      variant="primary"
      type="submit"
      // to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Button>
  );
};

export const TextEmailModal = ({
  modalOn = false,
  toRemoveOn = false,
  handleClose,
  mail,
  children,
}: PropsTextEmailModal) => {
  const [textObject, setTextObject] = useState("Invitation à une réunion");

  // // TODO: using sendForm semble approprié
  // const onSendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   handleClose();
  // };

  if (!mail["to"]) {
    return (
      <Alert variant={"warning"} show={modalOn}>
        {" "}
        Impossible d'envoyer un mail car l'adresse mail n'est pas entrée{" "}
      </Alert>
    );
  }

  return (
    <Modal
      show={modalOn}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
    >
      <Modal.Header closeButton>
        <Modal.Title> {"Le mail à " + mail["to"]} </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p style={{ textAlign: "left" }}>
          {" "}
          Objet <strong> {textObject} </strong>
        </p>
        <br />
        {children}
        <br />
        Vous pouvez copier le texte ci-dessous dans le mail qui va s'ouvrir.
        <ButtonMailto
          label="Envoyer"
          to={mail["to"]}
          subject={mail["subject"]}
          body={""} // renderToString(mail["body"])
        ></ButtonMailto>
      </Modal.Body>
    </Modal>
  );
};
