import { useState } from "react";
import { Accordion, Button, Col, Modal, Row } from "react-bootstrap";

import GoogleImport from "modules/GoogleSynch";
import OutlookImport from "modules/OutlookSynch";
import { CalendarScreenCapture } from "modules/ScreenCaptureAgenda";

export const ScreenCapture = () => {
  const [modalOn, setModalOn] = useState(false);
  const handleClose = () => setModalOn(false);

  return (
    <>
      <br />
      <Modal
        backdrop="static"
        keyboard={true}
        show={modalOn}
        onHide={handleClose}
        size="xl"
      >
        <Modal.Header closeButton>
          <h2> Faites une capture et contrôler le résultat </h2>
        </Modal.Header>
        <Modal.Body>
          <CalendarScreenCapture></CalendarScreenCapture>
        </Modal.Body>
      </Modal>
      <Button variant="info" onClick={() => setModalOn(true)}>
        {" "}
        Tester la capture d'écran{" "}
      </Button>
    </>
  );
};

export const ImportAgenda = ({ user_id }: { user_id: string }) => {
  return (
    <>
      <Row>
        <Col
          className="align-items-center"
          sm={8}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Accordion>
            <Accordion.Item eventKey="meth_1">
              <Accordion.Header>
                Importez vos agendas existants pour déterminer les créneaux
                disponibles
              </Accordion.Header>
              <Accordion.Body>
                Le contenu des événements n'est pas lu par DaTime. Rien n'est
                conservé à ce stade et quand vous aurez fermé cette page, on se
                souviendra uniquement des propositions.
                <Accordion style={{ margin: "5px", padding: "5px" }}>
                  <Accordion.Item eventKey="google">
                    <Accordion.Header>Google</Accordion.Header>
                    <Accordion.Body>
                      En général, les autorisations sont données par défaut pour
                      les comptes google, si ce n'est pas le cas, vous pouvez
                      nous transmettre dans l'onglet ses coordonnées et nous le
                      contacterons.
                      <GoogleImport user_id={user_id} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="outlook">
                    <Accordion.Header>Outlook</Accordion.Header>
                    <Accordion.Body>
                      En général, les autorisations sont données par défaut pour
                      les comptes google, si ce n'est pas le cas, vous pouvez
                      nous transmettre dans l'onglet ses coordonnées et nous le
                      contacterons.
                      <OutlookImport user_id={user_id} />
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="screen-catpure">
                    <Accordion.Header>Capture d'écran</Accordion.Header>
                    <Accordion.Body>
                      A utiliser en dernier recours
                      <ScreenCapture />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                Si ça ne fonctionne pas voir notre FAQ pour les problèmes
                courrants
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default ImportAgenda;
