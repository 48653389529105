import { useState } from "react";
import { Button, Modal, ModalFooter } from "react-bootstrap";

const suite_de_modal = [
  {
    header: <h2> Bienvenu sur DaTime !</h2>,
    body: (
      <p>
        DaTime est très jeune et c'est probablement votre première visite.
        L'objectif de DaTime est de faciliter la prise de rendez-vous qui prend
        parfois trop de temps.
      </p>
    ),
  },

  {
    header: <h2> Comment ça marche ? </h2>,
    body: (
      <ul style={{ marginTop: "5px" }}>
        <li>
          Vous allez pouvoir visualiser les propositions et y réagir très
          simplement.
        </li>
        <li>
          Vous pourrez ajouter des créneaux et dans certains cas avoir tout de
          suite un retour
        </li>
        <li>
          Si votre agenda est chargé, si c'est une réunion avec beaucoup de
          personne, il peut être intéressant d'importer les agendas que vous
          avez déjà.
        </li>
      </ul>
    ),
  },
  {
    header: <h2> Vous pouvez nous aider </h2>,
    body: (
      <p style={{ marginTop: "5px" }}>
        Nous vous demandons de l'indulgence et de l'exigence. Si cela ne vous
        convient pas, si vous rencontrez un bug ou si vous avez une
        recommandation , nous vous serons reconnaissant. Et promis dans quelques
        moins on ne fera plus appel à l'indulgence. Dites-nous tous{" "}
        <a href="https://datime.fr/contact">ici</a>
      </p>
    ),
  },
  {
    header: <h2> A vous de jouer !</h2>,
    body: (
      <p style={{ marginTop: "5px" }}>
        Un dernier point avant de vous laisser, tout ce que vous écrivez ici est
        confidentiel. On s'en sert pour trouver un créneau et c'est tout.
        <br />
        On espère que votre expérience sera la plus agréable possible et que
        vous aurez de tirer le maximum de DaTime en vous économisant du temps et
        en vous simplifiant l'existence en devenant client.{" "}
        <a href="https://datime.fr/contact">Ecrivez-nous ici</a> , si vous êtes
        intéressés.
      </p>
    ),
  },
];

export const FirstVisitModal = () => {
  const [modalOn, setModalOn] = useState(true);
  const handleClose = () => setModalOn(false);
  const [idx, setIdx] = useState(0);

  return (
    <>
      <br />
      <Modal
        backdrop="static"
        keyboard={true}
        show={modalOn}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>{suite_de_modal[idx]["header"]}</Modal.Header>
        <Modal.Body>{suite_de_modal[idx]["body"]}</Modal.Body>
        <ModalFooter>
          <Button disabled={idx === 0} onClick={() => setIdx(idx - 1)}>
            {" "}
            Précédent{" "}
          </Button>

          <Button
            hidden={idx >= suite_de_modal.length - 1}
            onClick={() => setIdx(idx + 1)}
          >
            {" "}
            Suivant{" "}
          </Button>

          <Button
            hidden={idx < suite_de_modal.length - 1}
            onClick={() => handleClose()}
          >
            {" "}
            Fermer{" "}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
