
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    Configuration,
    PopupRequest
  } from "@azure/msal-browser";

// voir ici : https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
export const msalConfig: Configuration = {
    auth: {
        clientId: "6a6a7945-9e75-42ff-8488-e4e1b3a797f3",
        authority: "https://login.microsoftonline.com/common", // 'https://login.microsoftonline.com/{your_tenant_id}'
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    system: {
        allowNativeBroker: false // Disables WAM Broker
    }
};


export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
  }
})

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
// console.log("apres msalInstance", msalInstance)
