import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Accordion, Button, Col, Row } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

import { useDeleteMeetingMutation } from "store/features/meetings";
import { useGetUserQuery } from "store/features/user";
import { ModalConnected } from "../../../../modules/ModalConnected";
import { MeetingForm } from "./MeetingForm";
import { PersonnalizationMeetingForm } from "./PersonnalizationForm";

const EditMeeting = () => {
  let navigate = useNavigate();
  const [modalAlertOn, setModalAlertOn] = useState(true);
  const { id_meeting } = useParams();
  const { data: connectedUser } = useGetUserQuery();

  const [deleteMeeting] = useDeleteMeetingMutation();

  useEffect(() => {
    if (connectedUser) {
      setModalAlertOn(false);
    } else {
      setModalAlertOn(true);
    }
  }, [connectedUser]);

  const handleCloseAlert = () => {
    navigate("/my-meetings");
  };

  return (
    <>
      <ModalConnected modalOn={modalAlertOn} handleClose={handleCloseAlert} />
      <h1>Organiser un rendez-vous en quelques clics</h1>
      <h3> Caractéristiques de la réunion </h3>
      {/* { meetingPage.map( (item:MeetingType) => { return (<p> { item.id } </p>) } ) }  */}
      {/* <div style={{width: '100%', justifyContent: 'center', display: "flex"}}> <SimpleRdvTypeSelect/> </div>   */}
      <p style={{ margin: "10px" }}>
        Le nom, le type et la durée seront visibles par les invités et présent
        dans le mail qu'ils recevront. Ensuite, tout ce que vous entrerez sera
        personnel
      </p>

      <h3>
        {" "}
        ATTENTION : si vous avez déjà envoyé les invitations, vous devez
        prévenir vous-même les participants (notamment pour la durée et le nom
        de la réunion)
      </h3>
      <div>
        <Row>
          <Col lg={5}>
            <h3> Caractéristiques </h3>
            <br />
            {id_meeting ? <MeetingForm id_meeting={id_meeting} /> : null}

            <Row
              style={{ justifyContent: "center", marginTop: "8px" }}
              className="align-items-center"
              sm={8}
            >
              <Accordion>
                <Accordion.Item eventKey="param_perso">
                  <Accordion.Header>Paramètres personnels</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Ce que vous entrez ici, vous est personnel et n'est connu
                      que de vous
                    </p>
                    {id_meeting ? (
                      <PersonnalizationMeetingForm id_meeting={id_meeting} />
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>
          </Col>
          <Col style={{ alignItems: "center", justifyContent: "center" }}>
            <h3> Participants </h3>
            <Button
              size="lg"
              onClick={() => navigate("/meeting/invitations/" + id_meeting)}
            >
              Faire des modification sur les participants{" "}
            </Button>
            <br />
            {/* Ajouter une table participants le moment venu
            <TableParticipants
              id_meeting={id_meeting}
              connectedUser={connectedUser}
            /> */}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Button size="lg" onClick={() => navigate("/my-meetings")}>
              Sauvegarder les changements
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                if (id_meeting) {
                  deleteMeeting(id_meeting);
                  console.log("delete meeting", id_meeting);
                  navigate("/my-meetings");
                }
              }}
            >
              Annuler le rendez-vous
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditMeeting;
