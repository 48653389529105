import { useSelector } from "react-redux";

import { Button } from "react-bootstrap";

import { EventsSelector } from "../store/selectors";
import { useGoogleGapi, useGoogleGis } from "./useGoogle";

import { useCreateEventMutation } from "store/features/events";
import { createId } from "../api/createId";
import { TypeEvent } from "../types/DaTimeEvents";

// inspiration : https://github.com/fullcalendar/fullcalendar/tree/main/packages/google-calendar/src
// inspiration 2 : la doc de google developper

const config_google_api = {
  clientId: process.env.REACT_APP_CLIENT_ID_GOOGLE as string,
  apiKey: process.env.REACT_APP_API_KEY_GOOGLE as string,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const GoogleImport = ({ user_id }: { user_id: string }) => {
  const events = useSelector(EventsSelector);
  const [createEvent] = useCreateEventMutation();

  useGoogleGapi();
  const token = useGoogleGis() as any;
  // console.log('pourquoi pas de token ?', token)

  const styleElement = {
    backgroundColor: "rgba(7, 124, 240, 0.1)",
    justifyContent: "center",
    alignItems: "center",
  };

  const googleItemToFullCalendar = (item: any): TypeEvent => {
    let url = item.htmlLink || null;
    return {
      type: "event",
      start: item.start.dateTime || item.start.date, // try timed. will fall back to all-day
      end: item.end.dateTime || item.end.date, // same
      id: createId(), // should be related to item.id,
      allDay: false,
      title: item.summary,
      // id_meeting: [],
      SharedWith: [],
      lieu: item.location,
      user_id: user_id ? user_id : "",
      transport: null,
      rappel: null,
      priority: 1,
      days_of_week: [],
      resourceId: item.recurringEventId ? item.recurringEventId : "",
      // extendedProps: (item.extendedProperties || {}).shared || {},
    };
  };

  const handleAuthClick = () => {
    const gapi = window.gapi;
    token.callback = async (resp: any) => {
      if (resp.error !== undefined) {
        throw resp;
      }

      const request = {
        calendarId: "primary",
        timeMin: new Date().toISOString(),
        showDeleted: false,
        singleEvents: true,
        maxResults: 100,
        // 'orderBy': 'startTime', # impossible quand on prend les recurrings events
      };

      try {
        const response = await gapi.client.calendar.events.list(request);
        console.log("Reponse of Google Calendar is OK:", response);
        const events_from_google = response.result.items;
        console.log("events_from_google", events_from_google);
        // const eventsFC = events.map(googleItemToFullCalendar)
        // console.log('output', eventsFC)
        console.log("events before dispatch", events);
        const non_recurring_events_from_google = events_from_google.filter(
          (item: any) => !("recurringEventId" in item)
        );
        console.log(
          "non_recurring_events_from_google",
          non_recurring_events_from_google
        );
        non_recurring_events_from_google.forEach((item: any) => {
          let fc_item = googleItemToFullCalendar(item);
          createEvent(fc_item);
        });
        console.log("events after dispatch", events);

        console.log("events_from_google", events_from_google);
      } catch (err) {
        console.log("Error in requesting Google Calendar", err);
      }
    };

    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      token.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      token.requestAccessToken({ prompt: "" });
    }

    console.log("onclick in handleAuthClick");
    return "";
  };

  return (
    <>
      {/* <script src='https://apis.google.com/js/api.js' onLoad={gapiLoaded()} async={true} > </script> */}
      <div style={{ ...styleElement, borderRadius: "25px" }}>
        <Button onClick={handleAuthClick}> Importer un agenda Google</Button>
        {/* <p>Ce n'est pas forcément l'agenda associé à votre compte supabase</p> */}
      </div>
    </>
  );
};

export default GoogleImport;
