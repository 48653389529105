import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { supabase } from '../components/auth/supabaseClient';
import { RappelType } from '../types/Rappel';
import { TransportType } from '../types/transport';


type ProfileType = {
    id: string,
    rappel: RappelType[] | null,
    transport: TransportType | null,
    lieu: string | null,
    vocabulaire: string[] | null,
}

const InitialProfile:ProfileType = {
    id: '',
    rappel: null,
    transport: null,
    lieu: null,
    vocabulaire: null
}

export const DBupdateProfile= createAsyncThunk('DBupdateProfile',
    async (plainEventObject: ProfileType) => {
        const { data, error } = await supabase
            .from('daters')
            .update({ ...plainEventObject })
            .eq('id', plainEventObject.id)

        if (error) {
            throw { error };
        }
        return plainEventObject
    }
)


export const DBrequestProfile = createAsyncThunk('DBrequestProfile',
    async () => {
        const { data: dataAuth, error: errorAuth } = await supabase.auth.getUser()
        if (errorAuth){
            console.log('Probleme dans la récupération des identifiants', errorAuth)
        }
        const userId = dataAuth.user?.id
        if (userId){
            const { data: dataProfiles, error:errorProfiles } = await supabase
                .from('profiles')
                .select()
                .eq('id', userId)

            if (errorProfiles) {
                throw { errorProfiles };
            }
            return dataProfiles[0] as ProfileType
        }
    }
)

const ProfileSlice = createSlice({
    name: "Profile",
    initialState: InitialProfile,
    reducers: {
        updateProfile: (profile, action) => {
            return profile
        },
        // deleteProfile:  à faire quand on en aura l'usage
        flushProfile: () => {
            return InitialProfile
        }
    },
    extraReducers(builder) {
        builder
            .addCase(DBupdateProfile.fulfilled, (profile, action) => {
                return action.payload
                })
            .addCase(DBrequestProfile.fulfilled, (daters, action) => {
                return action.payload
                })
    }
    
});

export const { actions: StateActions, reducer: StateReducer } = ProfileSlice 

export const { updateProfile, flushProfile } = StateActions

export default ProfileSlice.reducer