import { useNavigate } from "react-router-dom";

import { Button } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

const MeetingError = () => {
  let navigate = useNavigate();
  return (
    <>
      <h1>La page demandée n'existe pas </h1>
      <h3> Il peut s'agir d'une erreur ou d'une réunion déjà passée </h3>
      <h3>
        {" "}
        Il est aussi possible que vous cherchiez à accéder aux information pour
        laquelle vous n'êtes pas inscrit comme participant{" "}
      </h3>

      <p>
        {" "}
        Si vous pensez que vous auriez dû voir cette page ou si vous avez une
        remarque : dites le nous !
        <Button
          onClick={() => {
            navigate("/contact");
          }}
        >
          {" "}
          contact{" "}
        </Button>
      </p>
    </>
  );
};

export default MeetingError;
