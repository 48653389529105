
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Accordion, Button, Col, Container, Row } from 'react-bootstrap';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

type CustomToggleType = {
    children: any,
    eventKey: string
}

function CustomToggle({ children, eventKey }: CustomToggleType) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
    );
  
    return (
      <Button onClick={decoratedOnClick} >
            {children}
        </Button>
    );
  }

const Decision = () => {

    let navigate = useNavigate();
    const { id_meeting } = useParams();

    const styleElement = { 
        backgroundColor: 'rgba(7, 124, 240, 0.1)',
        justifyContent: "center",
        alignItems: "center",
    }

    const reponseFirstUser = () => {

        return (
            <>
                <h1> Vous êtes le premier à entrer vos disponibilités !</h1>
                <h3> Vous serez recontacté pour valider le créneau final </h3>
            </>
        )

    }

    return (
        <>

            <br /> 
            <p> Option 1:  </p>
            {reponseFirstUser()}
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <br /> 
            <Container>
                <Row>
                    <h2>DaTime a identifié un créneau disponible ! </h2> <br />
                    <h5>Il a déjà été validé par 1 participant sur 3</h5>
                </Row>
                <Row className="align-items-center" 
                    style={{ 
                        backgroundColor: 'rgb(139, 195, 74, 0.5)',
                        opacity: 1.0,
                        borderRadius: '25px',
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Col style={{border:"3px  solid  blue",  borderRadius: '25px'}}>
                        <h1 > Le 24 janvier à 15H00 </h1> <br />
                        <p> L'analyse de DaTime : les préférences ont pu être respectées. <br />
                            Le prochain créneau respectant autant les diponibilités est le 26 janvier à 11H30 
                        </p>
                    </Col>

                    <Col>
                        <Row className="align-items-center">
                            <Col> 
                                <h5> Ce créneau me convient </h5> 
                            </Col>
                            <Col> 
                                <Button variant="info" size="lg"> Ajouter à mon agenda </Button> 
                                <br />
                                DaTime reviendra vers moi en cas de changement
                            </Col> 
                        </Row>
                        <br />
                        <Row className="align-items-center">
                            <Col> 
                                <h5>  Mes préférences ou mes disponibilités ont changé </h5> 
                            </Col>
                            <Col> 
                                <Button variant="info" size="lg"> Modifier mes infos </Button> 
                                <br />
                                Et trouver un nouveau créneau
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
                <Row>
                    <h3>Ajouter le rendez-vous à votre calendrier</h3>
                    <AddToCalendarButton
                        name="Title"
                        options={['Apple','Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo' ]}
                        buttonsList = {true}
                        buttonStyle = 'neumorphism'
                        location="World Wide Web"
                        startDate="2024-02-22"
                        endDate="2024-02-22"
                        startTime="10:15"
                        endTime="23:30"
                        timeZone="America/Los_Angeles"
                        />
                </Row>
                <br /> <br /> <hr /> <hr /> <br />
                <Row>
                    <h2>DaTime a identifié un créneau disponible ! </h2> <br />
                    <h5>Il a déjà été validé par 1 participant sur 3</h5>
                </Row>
                <Row className="align-items-center"
                    style={{ 
                        backgroundColor: 'rgb(255, 152, 0, 0.7)',
                        opacity: 1.0,
                        borderRadius: '25px',
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <Col style={{border:"3px  solid  blue",  borderRadius: '25px'}}>
                        <h1 > Le 31 janvier à 15H00 </h1> <br />
                        <p> L'analyse de DaTime : il a été difficile de trouver un créneau et les préférences n'ont pas pu être toutes respectées <br />
                            Il n'a pas été trouvé d'autres créneaux avant le 15 février 
                        </p>
                    </Col>

                    <Col>
                        <Row className="align-items-center">
                            <Col> 
                                <h5> Ce créneau me convient </h5> 
                            </Col>
                            <Col> 
                                <Button variant="info" size="lg"> Ajouter à mon agenda </Button> 
                                <br />
                                DaTime reviendra vers moi en cas de changement
                            </Col> 
                        </Row>
                        <br />
                        <Row className="align-items-center">
                            <Col> 
                                <h5> Changer mes préférences pour trouver un créneau plus tôt </h5> 
                            </Col>
                            <Col> 
                                <Button variant="info" size="lg"> Modifier mes infos </Button> 
                                <br />
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col> 
                                <h5> Demander plus de disponibilité </h5> 
                            </Col>
                            <Col> 
                                <Button variant="info" size="lg"> Chercher un meilleur créneau plus tard </Button> 
                                <br />
                                Nous allons demander de nouvelles disponibilités aux autres particpants
                            </Col>
                        </Row>
                        
                    </Col>


                    <h3>Ajouter le rendez-vous à votre calendrier</h3>
                    <AddToCalendarButton
                        name="Title"
                        options={['Apple','Google', 'iCal', 'Microsoft365', 'MicrosoftTeams', 'Outlook.com', 'Yahoo' ]}
                        buttonsList = {true}
                        buttonStyle = 'neumorphism'
                        location="World Wide Web"
                        startDate="2024-02-22"
                        endDate="2024-02-22"
                        startTime="10:15"
                        endTime="23:30"
                        timeZone="America/Los_Angeles"
                        ></AddToCalendarButton>
                </Row>
            </Container>
        </>
    )
}

export default Decision