import { DateTime } from 'luxon'

export const convert_date_to_utc_without_second = (local_time:string) :string => {
    // TODO: faire sans luxon (non prioritaire)
    const dt = DateTime.fromISO(local_time)
    dt.set({second: 0, millisecond: 0})
    let result = dt.toUTC().toISO({suppressMilliseconds:true, suppressSeconds:true})
    if (result) {return result } else {
        new Error('probleme dans la date')
        return 'probleme de date'
    }
}

export const convert_time_to_utc = (local_time:string) :string => {
    const dt = DateTime.fromISO(local_time)
    return dt.toUTC().toFormat('HH:mm')
}

export const convert_time_without_second = (local_time:string) :string => {
    console.log('A VERIFIER !!')
    const dt = DateTime.fromISO(local_time)
    dt.set({second: 0, millisecond: 0})
    let result = dt.toUTC().toISOTime({suppressMilliseconds:true, suppressSeconds:true})
    if (result) {return result } else {
        new Error('probleme dans la date')
        return 'probleme de date'
    }
}


// const dispo_to_new_proposition = (dispo:TypeDispo) : TypeProposition => {
//     let new_dispo = {
//         id : dispo.id,
//     }
// }