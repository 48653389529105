import { createId } from "api/createId";
import { ButtonGroup, Form, Table, ToggleButton } from "react-bootstrap";
import { User } from "store/SessionReducer";
import { InvitedMeetingType, ModeContatType } from "types/Dater";

type TableParticipantsProps = {
  participants: InvitedMeetingType[];
  setParticipants: any; // () => void;
  id_meeting: string;
  connectedUser: User;
};

function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

export const TableParticipants = ({
  participants,
  setParticipants,
  id_meeting,
  connectedUser,
}: TableParticipantsProps) => {
  const update_participants = (new_invit: InvitedMeetingType) => {
    const idx = participants.findIndex(
      (item: InvitedMeetingType) => item.id === new_invit.id
    );
    const new_inviteds = [...participants];
    new_inviteds.splice(idx, 1, new_invit);
    setParticipants(new_inviteds);
  };

  const addTableRows = () => {
    const rowsInput: InvitedMeetingType = {
      id: createId(),
      id_person: createId(),
      id_meeting: id_meeting,
      added_by: connectedUser.id,
      name: "",
      mail: "",
      organisator: false,
      status: "to_invite",
      mode_contact: "datime",
      mandatory: true,
      nb_asked: 0,
      my_duration: 60,
      my_object: "",
    };
    setParticipants([...participants, rowsInput]);
  };

  const ChoseModeContact = (invit: InvitedMeetingType) => {
    if (invit.organisator) {
      return <p> </p>;
    }
    return (
      <ButtonGroup>
        <ToggleButton
          key={"1" + invit["id"]}
          id={`modeContact-1` + invit["id"]}
          type="radio"
          name={`modeContact` + invit["id"]}
          value={"datime"}
          checked={invit["mode_contact"] === "datime"}
          onChange={(e) =>
            update_participants({
              ...invit,
              mode_contact: e.currentTarget.value as ModeContatType,
            })
          }
        >
          {"mail DaTime"}
        </ToggleButton>
        <ToggleButton
          key={"2" + invit["id"]}
          id={`modeContact-2` + invit["id"]}
          type="radio"
          name={`modeContact` + invit["id"]}
          value={"myself"}
          checked={invit["mode_contact"] === "myself"}
          onChange={(e) =>
            update_participants({
              ...invit,
              mode_contact: e.currentTarget.value as ModeContatType,
            })
          }
        >
          {"par moi-même"}
        </ToggleButton>
      </ButtonGroup>
    );
  };

  const row_invite = (invit: InvitedMeetingType) => {
    return (
      <tr key={"tr" + invit["id"]}>
        <td>
          <Form key={"form-email" + invit["id"]}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder={invit["mail"] ? invit["mail"] : "name@example.com"}
                onBlur={(evnt) => {
                  update_participants({
                    ...invit,
                    mail: evnt.target.value,
                    name: invit["name"]
                      ? invit["name"]
                      : evnt.target.value.split("@")[0].replace(".", " "),
                  });
                }}
              />
            </Form.Group>
          </Form>
        </td>
        <td>
          <Form key={"form-name" + invit["id"]}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder={invit["name"] ? invit["name"] : "Prénom"}
                onBlur={(evnt) =>
                  update_participants({
                    ...invit,
                    name: evnt.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </td>
        {/* <td>{ChoseModeContact(invit)}</td> */}
        <td>
          {" "}
          {invit["mandatory"] ? "Oui" : "Non"}
          <Form key={"form-mandatory" + invit["id"]}>
            <Form.Check
              type="switch"
              id="custom-switch"
              defaultChecked={true}
              onBlur={(evnt) => {
                update_participants({
                  ...invit,
                  mandatory: evnt.target.checked,
                });
              }}
            />
          </Form>
        </td>
        <td>
          <button
            className="btn btn-outline-danger"
            onClick={() => {
              const without_participant = participants.filter(
                (part) => part["id"] !== invit["id"]
              );
              setParticipants(without_participant);
            }}
          >
            x
          </button>
        </td>
      </tr>
    );
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Mail</th>
          <th>Nom</th>
          <th>Présence impérative</th>
          <th>Supprimer</th>
        </tr>
      </thead>
      <tbody>
        <>
          {participants.map((item, idx) => row_invite(item))}
          <tr>
            <td colSpan={100}>
              <button
                className="btn btn-outline-success"
                onClick={addTableRows}
              >
                Ajouter un participant
              </button>
            </td>
          </tr>
        </>
      </tbody>
    </Table>
  );
};
