import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { MeetingSelector } from "../store/selectors";

export const RedirectMeetingWithoutIdInUrl = () => {
  // const events = useSelector(EventsSelector)
  let navigate = useNavigate();
  const { id_meeting_url } = useParams();

  const localMeetingPage = useSelector(MeetingSelector);

  useEffect(() => {
    if (id_meeting_url) {
      navigate("/meeting/" + localMeetingPage["id"]);
    } else {
      navigate("/meeting/new");
    }
  }, []);

  console.log("redirect in action");

  return null;
};
