import { Accordion, Col, Form, Row } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { durations } from "data/durations";
import { fr } from "date-fns/locale/fr";
import {
  useGetMeetingByIdQuery,
  useUpdateMeetingMutation,
} from "store/features/meetings";
import { MeetingType } from "types/Meeting";
registerLocale("fr", fr);

type MeetingFormProps = {
  newMeeting: MeetingType;
  setNewMeeting: any; // () => void;
};

export const MeetingForm = ({ id_meeting }: { id_meeting: string }) => {
  let in_one_month = new Date();
  in_one_month.setMonth(in_one_month.getMonth() + 2);
  const { data: meetingPage } = useGetMeetingByIdQuery(id_meeting);
  const [updateMeeting] = useUpdateMeetingMutation();

  return (
    <Form>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={3}>
          Objet de la réunion
        </Form.Label>
        <Col sm={7}>
          <Form.Control
            type="text"
            id="inputObjectMeeting"
            defaultValue={meetingPage ? meetingPage["object"] : ""}
            placeholder={meetingPage ? meetingPage["object"] : "Entrez un nom"}
            onBlur={(e) => {
              if (meetingPage) {
                updateMeeting({ ...meetingPage, object: e.target.value });
              }
            }}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label as="legend" column sm={3}>
          Durée de la réunion
        </Form.Label>
        <Col sm={7}>
          <Form.Select
            aria-label="Default select example"
            id="inpuTypeMeeting"
            defaultValue={meetingPage ? meetingPage["duration"].toString() : 0}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (meetingPage) {
                updateMeeting({
                  ...meetingPage,
                  duration: parseInt(e.target.value),
                });
              }
            }}
          >
            <option>Choisir la durée de la réunion</option>
            {durations.map((item) => {
              return (
                <option value={item["minutes"]} key={item["value"]}>
                  {" "}
                  {item["label"]}{" "}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Form.Group>
      <hr />

      <Accordion>
        <Accordion.Item eventKey="meth_1">
          <Accordion.Header>Autres options</Accordion.Header>
          <Accordion.Body>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalPassword"
            >
              <Form.Label column sm={3}>
                Type de réunion
              </Form.Label>
              <Col sm={7}>
                {/* <Form.Control type="password" placeholder="Password" /> */}
                <Form.Select
                  aria-label="Default select example"
                  id="inpuTypeMeeting"
                  value={meetingPage ? meetingPage["simpleType"] : 0}
                  onChange={(event: any) => {
                    if (meetingPage) {
                      updateMeeting({
                        ...meetingPage,
                        simpleType: event.target.value,
                      });
                    }
                  }}
                >
                  <option>Type de réunion</option>
                  <option value="normal">Réunion classique</option>
                  <option value="dej">Déjeuner</option>
                  <option value="soirée">Fin de journée</option>
                  <option value="normal+dej">
                    Réunion classique ou déjeuner
                  </option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={3}>
                La réunion doit être placée à partir de :
              </Form.Label>
              <Col sm={7}>
                <Form.Select
                  aria-label="Default select example"
                  id="inpuTypeMeeting"
                  onChange={(event: any) => {
                    var result = new Date();
                    if (event.target.value === "1") {
                      result.setDate(
                        result.getDate() + ((1 + 7 - result.getDay()) % 7 || 7)
                      );
                    }
                    if (event.target.value === "2") {
                      result.setDate(
                        result.getDate() +
                          ((1 + 7 - result.getDay()) % 7 || 7) +
                          7
                      );
                    }
                    if (event.target.value === "3") {
                      result.setDate(result.getDate() + 28);
                    }
                    let formatted_date = result.toISOString().split("T")[0];
                    if (meetingPage) {
                      updateMeeting({
                        ...meetingPage,
                        startDate: formatted_date,
                      });
                    }
                  }}
                >
                  <option>maintenant</option>
                  <option value="1">lundi prochain</option>
                  <option value="2">le lundi d'après</option>
                  <option value="3">dans un mois</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label as="legend" column sm={3}>
                La réunion doit absoluement se tenir avant : <br />{" "}
                <span style={{ fontSize: "80%" }}> deux mois par défaut</span>
              </Form.Label>
              {meetingPage ? (
                <Col sm={7}>
                  Faire le DatePicker
                  {/* <DatePicker
            selected={meetingPage['endDate'] as Date}
            onChange={ (date: Date) => 
                updateMeeting({
                  ...meetingPage,
                  endDate: date,
                });
              
            }
            locale={"fr"}
            dateFormat={["P"]}
          /> */}
                </Col>
              ) : null}
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Lieu
              </Form.Label>
              <Col sm={7}>
                <Form.Control
                  type="text"
                  id="inputObjectMeeting"
                  defaultValue={meetingPage ? meetingPage["location"] : ""}
                  placeholder={
                    meetingPage ? meetingPage["location"] : "Entrez un nom"
                  }
                  onBlur={(e) => {
                    if (meetingPage) {
                      updateMeeting({
                        ...meetingPage,
                        location: e.target.value,
                      });
                    }
                  }}
                />
              </Col>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Form>
  );
};
