import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  Button,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

import { InvitedMeetingType } from "types/Dater";

import { createId } from "api/createId";

import { ModalConnected } from "modules/ModalConnected";
import { useCreateMeetingMutation } from "store/features/meetings";
import { useCreateParticipantsListMutation } from "store/features/participants";
import { useGetUserQuery } from "store/features/user";
import { MeetingType } from "types/Meeting";
import { MeetingForm } from "./MeetingForm";
import { PersonnalizationMeetingForm } from "./PersonnalizationForm";
import { TableParticipants } from "./TableParticipants";

const default_type_RDV = {
  value: 10,
  label: "type_de_rdv",
  times: [{ start: "09:30:00", end: "12:30:00", tz: "UTC" }],
};

export const Organize = () => {
  let navigate = useNavigate();

  // on met unset_meeting ici pour être sûr de reconstruire un nouvel id à chaque chargement de la page
  const unset_meeting = {
    id: createId(),
    created_by: null,
    simpleType: "normal",
    type: default_type_RDV,
    duration: 60,
    // participants: [],
    location: "",
    object: "",
  } as MeetingType;

  const [newMeeting, setNewMeeting] = useState<MeetingType>(unset_meeting);
  const [newParticipants, setNewParticipants] = useState<InvitedMeetingType[]>(
    []
  );

  console.log("newParticipants", newParticipants);

  const [myMeeting, setMyMeeting] = useState<InvitedMeetingType | null>();

  const [modalAlertOn, setModalAlertOn] = useState(true);
  const [crateMeeting] = useCreateMeetingMutation();
  const [addParticipants] = useCreateParticipantsListMutation();
  const { data: connectedUser } = useGetUserQuery();

  useEffect(() => {
    if (connectedUser) {
      setModalAlertOn(false);
    } else {
      setModalAlertOn(true);
    }
  }, [connectedUser]);

  useEffect(() => {
    const user_as_invited: InvitedMeetingType = {
      id: createId(),
      id_person: connectedUser ? connectedUser.id : "",
      id_meeting: newMeeting["id"],
      added_by: connectedUser ? connectedUser.id : "",
      mail: connectedUser ? connectedUser.email : ("" as string),
      status: "to_invite",
      organisator: true,
      mode_contact: "unecessay",
      mandatory: true,
      my_duration: 0,
      my_object: newMeeting ? newMeeting["object"] : "",
      nb_asked: 0,
      is_DaTimer: true,
    };
    if (connectedUser) {
      let already_in_invited = newParticipants.filter((item) => {
        return item.mail === connectedUser.email;
      });
      if (already_in_invited.length === 0) {
        setNewParticipants([...newParticipants, user_as_invited]);
        setMyMeeting(user_as_invited);
      }
    }
  }, [connectedUser, newMeeting, newParticipants]);

  const handleCloseAlert = () => {
    navigate("/");
  };

  const participant_to_send = (participant: InvitedMeetingType) => {
    // on update les participants avant d'envoyer avec les informations du meeting que l'on met par défaut
    if (connectedUser) {
      if (participant.mail === connectedUser.email) {
        return {
          ...participant,
          my_duration:
            participant["my_duration"] === 0
              ? newMeeting["duration"]
              : participant["my_duration"],

          my_object:
            participant["my_object"] === ""
              ? newMeeting["object"]
              : participant["my_object"],
        };
      }
    }
    return {
      ...participant,
      my_duration: newMeeting["duration"],
      my_object: newMeeting["object"],
    };
  };

  const onMeetingCreation = () => {
    let meeting_to_send = {
      ...newMeeting,
      created_by: connectedUser?.id,
    };
    if (newMeeting) {
      crateMeeting(meeting_to_send).then(() => {
        // setNewParticipants(newParticipants.map(participant_to_send));
        console.log("on a bien créer", meeting_to_send);
        addParticipants(newParticipants.map(participant_to_send)).then(() => {
          console.log("participants, c'est bon non ? ", newParticipants);
          navigate("/availabilities/" + newMeeting["id"]);
        });
      });
    }
  };

  return (
    <>
      <ModalConnected modalOn={modalAlertOn} handleClose={handleCloseAlert} />
      <h1>Organiser un rendez-vous en quelques clics</h1>
      <h3> Caractéristiques de la réunion </h3>
      <p>
        Le nom, le type et la durée seront visibles par les invités et présent
        dans le mail qu'ils recevront. Ensuite, tout ce que vous entrerez sera
        personnel
        <br />
        <br />
        <br />
      </p>

      <Col
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row lg={12}>
          <Col lg={5}>
            <h3> Caractéristiques </h3>
            <br />
            <MeetingForm
              newMeeting={newMeeting}
              setNewMeeting={setNewMeeting}
            />

            <Row style={{ justifyContent: "center", marginTop: "8px" }} sm={2}>
              <Accordion>
                <Accordion.Item eventKey="param_perso">
                  <Accordion.Header>Paramètres personnels</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Ce que vous entrez ici, vous est personnel et n'est connu
                      que de vous
                    </p>
                    {myMeeting ? (
                      <PersonnalizationMeetingForm
                        myMeeting={myMeeting}
                        setMyMeeting={setMyMeeting}
                        duration_meeting={newMeeting["duration"]}
                      />
                    ) : null}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>
          </Col>
          <Col>
            <h3> Participants </h3>
            <br />
            {connectedUser ? (
              <TableParticipants
                participants={newParticipants}
                setParticipants={setNewParticipants}
                id_meeting={newMeeting["id"]}
                connectedUser={connectedUser}
              />
            ) : (
              <h2> Utilisateur non connecté </h2>
            )}
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", marginTop: "15px" }}>
          <Col lg={4}>
            <Row>
              <Col>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={"tooltip-one-click"}>
                      {
                        <p>
                          DaTime envoie les créneaux dont il a connaissance et
                          contact les participants par mail
                        </p>
                      }
                    </Tooltip>
                  }
                >
                  <Button
                    size="lg"
                    onClick={() => {
                      alert(
                        "Pour l'instant, ce n'est pas fonctionnel mais c'est l'objectif. En attendant, vous pouvez cliquer sur l'autre bouton qui vous amène pas à pas à la même chose"
                      );
                      // onMeetingCreation();
                    }}
                  >
                    <h3>
                      Rendez-vous <br /> en un clic{" "}
                    </h3>
                  </Button>
                  {/* <InfoCircle></InfoCircle> */}
                </OverlayTrigger>
              </Col>
              <Col>
                <Button size="lg" onClick={() => onMeetingCreation()}>
                  Créer le rendez-vous et <br /> contrôler les créneaux qui
                  seront proposés
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};
