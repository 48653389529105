import { createId } from "api/createId";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  useCreatePlageOfRDVMutation,
  useDeletePlageOfRDVMutation,
  useGetPlagesofRDVQuery,
  useUpdatePlageOfRDVMutation,
} from "store/features/kind_of_rdv";
import { TimeRangeType } from "types/TimeRange";

const default_RDV = {
  id: "string",
  id_person: "id_person",
  value: 1,
  label: "Fake RDV",
  start: "08:00",
  end: "10:00",
};

const RowRDV = ({ plage }: { plage: TimeRangeType }) => {
  const [updatePlage] = useUpdatePlageOfRDVMutation();
  const [deletePlage] = useDeletePlageOfRDVMutation();

  return (
    <Row>
      <Col>
        {" "}
        {plage["start"]} 
        <Form.Group className="mb-3" controlId="Debut">
          <Form.Control
            type="time"
            defaultValue={plage["start"]}
            onBlur={(e) => {
              updatePlage({ id: plage.id, start: e.target.value });
            }}
          />
        </Form.Group>
      </Col>
      <Col>
        {" "}
        {plage["end"]} 
        <Form.Group className="mb-3" controlId="Fin">
          <Form.Control
            type="time"
            defaultValue={plage["end"]}
            onBlur={(e) => {
              updatePlage({ id: plage.id, end: e.target.value });
            }}
          />
        </Form.Group>
         
      </Col>
      <Col>
        <Button
          onClick={() => {
            if (plage["id"]) {
              deletePlage(plage["id"]);
            }
          }}
        >
          {" "}
          Supprimer cette plage{" "}
        </Button>
      </Col>
    </Row>
  );
};

// TODO : fare un tableau
export const PlageOfRDV = ({ id_rdv }: { id_rdv: string }) => {
  const { data: plages } = useGetPlagesofRDVQuery(id_rdv);
  const [createPlage] = useCreatePlageOfRDVMutation();

  return (
    <div>
      <Row>
        <Col> Début </Col>
        <Col> Fin </Col>
        <Col> Supprimer </Col>
      </Row>
      {plages
        ? plages.map((plage) => {
            return (
              <RowRDV
                key={plage["id"] + "-" + plage["id_rdv"]}
                plage={plage}
              ></RowRDV>
            );
          })
        : null}

      <Button
        onClick={() => {
          console.log("test on click");
          createPlage({
            id: createId(),
            id_rdv: id_rdv,
            start: "08:00",
            end: "13:00",
          });
        }}
      >
        {" "}
        Ajouter une plage pour ce type de rendez-vous
      </Button>
    </div>
    // <ToggleButton
    //   key={time["value"]}
    //   id={`radio-${time["value"]}`}
    //   type="radio"
    //   // variant="secondary"
    //   name="radio"
    //   value={time["value"]}
    //   checked={radioValue === time["value"]}
    //   onChange={(e) => setRadioValue(parseInt(e.currentTarget.value))}
    // >
    //   {time["label"]}
    // </ToggleButton>
  );
};
