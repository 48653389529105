export interface ICalEvent {
  title: string;
  startTime: string;
  description?: string;
  endTime?: string;
  location?: string;
  attendees?: string[];
  url?: string;
}

function pad(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}

export function formatDate(dateString: string): string {
  const dateTime = new Date(dateString);
  return [
    dateTime.getUTCFullYear(),
    pad(dateTime.getUTCMonth() + 1),
    pad(dateTime.getUTCDate()),
    "T",
    pad(dateTime.getUTCHours()),
    pad(dateTime.getUTCMinutes()) + "00Z",
  ].join("");
}

export const make_calendar_from_events = (
  vevents: string[],
  useDataURL: boolean = false
) => {
  const url = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "PRODID:-//DaTime//V1//",
    vevents.join("\n"),
    "END:VCALENDAR",
  ].join("\n");

  if (useDataURL) {
    return encodeURI(`data:text/calendar;charset=utf8,${url}`);
  } else {
    return url;
  }
};

export function buildUrl(
  events: ICalEvent[],
  useDataURL: boolean = false,
  rawContent: string = ""
): string {
  const bodies: string[] = events.map((event) => {
    const body: string[] = [];
    if (!event || !event.startTime || !event.title)
      throw Error("Both startTime and title fields are mandatory");

    body.push(`DTSTART:${formatDate(event.startTime)}`);
    body.push(`SUMMARY:${event.title}`);

    event.url && body.push(`URL:${event.url}`);
    event.attendees &&
      event.attendees.forEach((attendee) => {
        const regExp = /^([^<]+)\s*<(.+)>/;
        const matches = attendee.match(regExp);
        if (matches) {
          const name = matches[1];
          const email = matches[2];
          body.push(
            [
              "BEGIN:VEVENT",
              "ATTENDEE",
              `CN=${name}`,
              "CUTYPE=INDIVIDUAL",
              "PARTSTAT=NEEDS-ACTION",
              "ROLE=REQ-PARTICIPANT",
              `RSVP=TRUE:mailto:${email}`,
              "END:VEVENT",
            ].join(";")
          );
        }
      });
    event.endTime && body.push(`DTEND:${formatDate(event.endTime)}`);
    event.description && body.push(`DESCRIPTION:${event.description}`);
    event.location && body.push(`LOCATION:${event.location}`);
    rawContent && body.push(rawContent);
    return body.join("\n");
  });

  return make_calendar_from_events(bodies, useDataURL);
}

export function downloadBlob(blob: Blob, filename: string): void {
  const linkEl = document.createElement("a");
  linkEl.href = window.URL.createObjectURL(blob);
  linkEl.setAttribute("download", filename);
  document.body.appendChild(linkEl);
  linkEl.click();
  document.body.removeChild(linkEl);
}
