import { Button } from "react-bootstrap";
import {
  useCreatePlageOfRDVMutation,
  useDeleteKindOfRDVMutation,
} from "store/features/kind_of_rdv";
import { KindOfRdvType } from "types/TimeRange";
import { PlageOfRDV } from "./plageRDV";

const default_RDV = {
  id: "string",
  id_person: "id_person",
  value: 1,
  label: "Fake RDV",
  start: "08:00",
  end: "10:00",
};

type CardRdvProps = {
  rdv: KindOfRdvType;
};

export const CardRDV = ({ rdv }: CardRdvProps) => {
  const [createPlage] = useCreatePlageOfRDVMutation();
  const [deleteRDV] = useDeleteKindOfRDVMutation();

  return (
    <div style={{ padding: "10px", flexDirection: "column" }}>
      <h3> {rdv["label"]}</h3>
      <PlageOfRDV id_rdv={rdv.id}></PlageOfRDV>
      <Button
        onClick={() => {
          deleteRDV(rdv["id"]);
        }}
      >
        {" "}
        Supprimer ce type de rendez-vous{" "}
      </Button>
    </div>
  );
};
