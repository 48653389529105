const ChronoptURL = process.env.REACT_APP_URL_API_CHRONOPT as string;
const originURL = process.env.REACT_APP_URL_Origin as string;

type helperPostChronoptType = {
    url_slash: string,
    json: string
}

export const helperPostChronopt = async ( {url_slash, json} : helperPostChronoptType ) => {
    try {
        const response = await fetch(ChronoptURL + url_slash, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: "omit", // include, *same-origin, omit
            // Access-Control-Allow-Origin: http://localhost:3000
            headers: {
                "Content-Type": "application/json",
                "Origin": originURL
            },
            // redirect: "follow", // manual, *follow, error
            // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: json, // body data type must match "Content-Type" header
            }
            );
        if (!response.ok) {
            throw new Error("Network response was not OK");
        }
        const json_returned = await response.json();
        return json_returned 
    } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
    }
}