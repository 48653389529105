import { createSlice } from "@reduxjs/toolkit";
import { InvitedMeetingType } from "../types/Dater";

const InvitedSlice = createSlice({
  name: "invitedSlice",
  initialState: [] as InvitedMeetingType[],
  reducers: {
    setInviteds: (inviteds, action: { payload: InvitedMeetingType[] }) => {
      console.log("in setInviteds", action.payload);
      return [...action.payload];
    },

    addInvited: (inviteds, action) => {
      return [...inviteds, action.payload];
    },

    updateInvited: (inviteds, action: { payload: InvitedMeetingType }) => {
      const idx = inviteds.findIndex(
        (item: InvitedMeetingType) => item.id === action.payload.id
      );
      const new_inviteds = [...inviteds];
      new_inviteds.splice(idx, 1, action.payload);
      return new_inviteds;
    },

    deleteInvited: (inviteds, action: { payload: string }) => {
      return inviteds.filter((item) => item.id !== action.payload);
    },

    flushInviteds: (inviteds) => {
      return [];
    },
  },
});

export const { actions: InvitedActions, reducer: InvitedReducer } =
  InvitedSlice;

export const { addInvited, updateInvited, deleteInvited, flushInviteds } =
  InvitedActions;

export default InvitedSlice.reducer;
