import { helperPostChronopt } from "api/post_chronopt_helper";

type stepChronopt = "init" | "next";

export const postChronopt = async (id_meeting: string, step: stepChronopt) => {
  const json = await helperPostChronopt({
    url_slash: "/chronopt",
    json: JSON.stringify({ id_meeting: id_meeting, step: step }),
  });

  return json;
};
