import { Button, Row } from "react-bootstrap";

import { createId } from "api/createId";
import {
  useCreateKindOfRDVMutation,
  useCreatePlageOfRDVMutation,
  useGetKindOfRDVofUserQuery,
} from "store/features/kind_of_rdv";
import { CardRDV } from "./CardRDV";

const default_RDV = {
  id: "string",
  id_person: "id_person",
  value: 1,
  label: "Fake RDV",
  start: "08:00",
  end: "10:00",
};

const DefineTypeRDV = ({ id_person }: { id_person: string | undefined }) => {
  const { data: listOfRdv } = useGetKindOfRDVofUserQuery(id_person);
  const [createRDV] = useCreateKindOfRDVMutation();

  const [createPlage] = useCreatePlageOfRDVMutation();

  return (
    <>
      <h2> Définir les types de rendez-vous</h2>
      {listOfRdv
        ? listOfRdv.map((rdv) => {
            return <CardRDV key={rdv.id} rdv={rdv}></CardRDV>;
          })
        : null}

      <Row>
        <h3> à venir </h3>
        <Button
          disabled
          onClick={() => {
            if (id_person) {
              let id_def = createId();
              createRDV({
                id: id_def,
                id_person: id_person,
                value: 1,
                label: "Entrer un nom",
              });

              createPlage({
                id: id_def,
                id_rdv: id_def,
                start: "00:00",
                end: "00:00",
              });
            }
          }}
        >
          {" "}
          Ajouter un type de rendez-vous{" "}
        </Button>
      </Row>
      <br />
    </>
  );
};

export default DefineTypeRDV;
