import SupabaseAuth from "components/auth/SupabaseAuth";
import { Modal } from "react-bootstrap";

type PropsModalConnectedType = {
  modalOn: boolean;
  handleClose: () => void;
};

export const ModalConnected = ({
  modalOn = false,
  handleClose,
}: PropsModalConnectedType) => {
  return (
    <Modal
      show={modalOn}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          {"Connectez-vous pour organiser une réunion"}{" "}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <SupabaseAuth />
      </Modal.Body>
    </Modal>
  );
};
