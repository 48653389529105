import { configureStore } from "@reduxjs/toolkit";
import {
  DebaucheReducer,
  EmbaucheReducer,
  LunchReducer,
} from "./AgendaReducer";
import DatersReducers from "./DatersReducer";
import dayslotsReducer from "./DaySlotsReducer";
import { StateReducer } from "./EventsReducer";
import { supabaseKindRdvApi } from "./features/kind_of_rdv";
import { supabaseMeetingApi } from "./features/meetings";
import { supabaseParticipantsApi } from "./features/participants";
import { supabaseUserApi } from "./features/user";
import InvitedReducer from "./InvitedReducer";
import MeetingReducer from "./MeetingReducer";
import ProfileReducer from "./ProfileReducer";
import AuthReducer from "./SessionReducer";
import weekendsVisibleReducer from "./WeekendReducer";
import { supabaseEventsApi } from "./features/events";
import { supabasePropositionsApi } from "./features/propositions";

const store = configureStore({
  reducer: {
    weekendsVisible: weekendsVisibleReducer,
    events: StateReducer,
    meeting: MeetingReducer,
    invited: InvitedReducer,
    // meetings: MeetingsReducer,
    daters: DatersReducers,
    dayslots: dayslotsReducer,
    auth: AuthReducer,
    profile: ProfileReducer,
    lunch: LunchReducer,
    embauche: EmbaucheReducer,
    debauche: DebaucheReducer,
    [supabaseMeetingApi.reducerPath]: supabaseMeetingApi.reducer,
    [supabaseUserApi.reducerPath]: supabaseUserApi.reducer,
    [supabaseParticipantsApi.reducerPath]: supabaseParticipantsApi.reducer,
    [supabaseKindRdvApi.reducerPath]: supabaseKindRdvApi.reducer,
    [supabaseEventsApi.reducerPath]: supabaseEventsApi.reducer,
    [supabasePropositionsApi.reducerPath]: supabasePropositionsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(supabaseMeetingApi.middleware)
      .concat(supabaseUserApi.middleware)
      .concat(supabaseParticipantsApi.middleware)
      .concat(supabaseKindRdvApi.middleware)
      .concat(supabaseEventsApi.middleware)
      .concat(supabasePropositionsApi.middleware),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
