import { createSlice } from '@reduxjs/toolkit'

const weekendsVisibleSlice = createSlice({
  name: "WeekendSlice",
  initialState: false,
  reducers: {
    toggleWeekends: (weekendsVisible) => {
      return !weekendsVisible
    }
  }
});


const { actions, reducer } = weekendsVisibleSlice 

export const { toggleWeekends } = actions
export default reducer
