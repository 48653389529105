export type ValueLabel = {
  readonly value: string;
  readonly label: string;
};

export const daysofweek: ValueLabel[] = [
  { value: "1", label: "Lundi" },
  { value: "2", label: "Mardi" },
  { value: "3", label: "Mercredi" },
  { value: "4", label: "Jeudi" },
  { value: "5", label: "Vendredi" },
  { value: "6", label: "Samedi" },
  { value: "0", label: "Dimanche" },
];

export const label_of_day = {
  "1": "Lundi",
  "2": "Mardi",
  "3": "Mercredi",
  "4": "Jeudi",
  "5": "Vendredi",
  "6": "Samedi",
  "0": "Dimanche",
} as const;

export const label_of_day_period = {
  "0": { label: "Matin", start: "08:00:00", end: "11:30" },
  "1": { label: "Midi", start: "11:30:00", end: "14:00" },
  "2": { label: "Soir", start: "14:00:00", end: "21:30" },
  "3": { label: "Soir tard", start: "22:00:00", end: "23:30" },
};

const optionsRdv = ["petit-déjeuner", "déjeuner", "un verre", "un diner"];
