import { helperPostChronopt } from "./post_chronopt_helper";

type basicSlot = {
  start: string;
  end: string;
};

export const postIsFree = async (id_meeting: string, slots: basicSlot[]) => {
  const json = await helperPostChronopt({
    url_slash: "/isFree",
    json: JSON.stringify({ id_meeting: id_meeting, slots: slots }),
  });

  return json;
};
