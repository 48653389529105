
import React, { useState, useMemo } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone'
import { ArrowRight, PlusCircle } from 'react-bootstrap-icons';
import { Container, Row, Col, Figure, Button } from 'react-bootstrap';

import logo from "../favicon-128.png"
import dispoPicture from "./exemple_dispo_depuis_screenshot.png"


const baseStyle = {
    className: 'dropzone',
    flex: "1",
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    padding: '10px',
    innerWidth: '50%',
    outerWidth: '50%',
    outerHeight: '200',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};
  
const focusedStyle = {
	borderColor: '#2196f3'
};

const acceptStyle = {
	borderColor: '#00e676'
};

const rejectStyle = {
	borderColor: '#ff1744'
};

function StyledDropzone(props: any) {
    const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragReject
    } = useDropzone({accept: {'image/*': []}});
  
    const style = useMemo(() => ({
		...baseStyle,
		...(isFocused ? focusedStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
    }), [
		isFocused,
		isDragAccept,
		isDragReject
    ]);
  
    return (
		<div className="container">
			<div {...getRootProps({style})} >
			<input {...getInputProps()} style={{height:'100'}}/>
			<em>(ou copier une capture)</em>
			<p style={{ color: "grey", padding: 5, margin: 0, fontSize: 14, height:'100%'}}>Englobez bien tout votre calendrier, pour que les jours et les horaires soient visibles</p>
			</div>
		</div>
    );
}

export const CalendarScreenCapture = () => {
    
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({accept: {'image/*': []}});

    return (

        <Container>
        <Row style={{alignItems:'center', display:"flex"}}>
            <Col xs={12} md={4}> <StyledDropzone /> </Col>
                
            <Col xs={12} md={1}><ArrowRight height={50}/></Col>
            <Col xs={12} md={7}>
                <Figure>
                    <Figure.Image
                        // width={171}
                        // height={180}
                        alt="171x180"
                        src={dispoPicture}
                    />
                    <Figure.Caption>
                        Une fois votre capture déposée, vos disponibilités remplaceront cet exemple <br></br>
                        <b>L'objet de vos rendez-vous n'est pas collecté</b>
                    </Figure.Caption>
                </Figure>
                </Col>
        </Row>
        <Row>
            <h5>Ajouter une capture</h5> <PlusCircle height={45}></PlusCircle>
        </Row>
        <Row>
            <h3> </h3>
        </Row>
    </Container>
    )
}
    