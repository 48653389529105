import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DaterType } from '../types/Dater';
import { supabase } from '../components/auth/supabaseClient';


const INITIAl_USERS : DaterType[] = [ // le format est celui utile pour le Select mais en vrai, on devrait avoir un id / label / email / etc
    { name: 'Alan', user_id: "1", groups: ['1'], id:'1'},
    { name: 'Bob', user_id: "2", groups: ['1'], id:'2' },
    { name: 'Docteur Jivago', user_id: "3", groups: [], id:'3' },
    { name: 'Coiffeur', user_id: "3", groups: [], id:'4', label:'Madame Tif' },
]


export const DBcreateDater = createAsyncThunk('DBcreateDater',
    async (plainEventObject: DaterType) => {
        const { data, error } = await supabase
            .from('daters')
            .insert([
                { ...plainEventObject },
            ])
            .select()
        if (error) {
            console.log('error dans event create', { error })
            throw { error };
        }
        return plainEventObject
    }
)

export const DBupdateDater= createAsyncThunk('DBupdateDater',
    async (plainEventObject: DaterType) => {
        const { data, error } = await supabase
            .from('daters')
            .update({ ...plainEventObject })
            .eq('id', plainEventObject.id)

        if (error) {
            throw { error };
        }
        return plainEventObject
    }
)

export const DBdeleteDater = createAsyncThunk('DBdeleteDater',
    async (Dater: DaterType) => {
        const { data, error } = await supabase
            .from('daters')
            .delete()
            .eq('id', Dater.id)
    
        if (error) {
            throw { error };
        }
        return Dater.id
    }
)

export const DBrequestDaters = createAsyncThunk('DBrequestDaters',
    async () => {
        const { data: dataAuth, error: errorAuth } = await supabase.auth.getUser()
        if (errorAuth){
            console.log('Probleme dans la récupération des identifiants', errorAuth)
        }
        const userId = dataAuth.user?.id
        if (userId){
            const { data: dataDaters, error:errorDaters } = await supabase
                .from('daters')
                .select()
                .eq('user_id', userId)

            if (errorDaters) {
                throw { errorDaters };
            }
            return dataDaters
        }
    }
)

const DatersSlice = createSlice({
    name: "Daters",
    initialState: INITIAl_USERS,
    reducers: {
        createDater: (daters, action) => {
            return [...daters, action.payload.daters]
        },
    
        updateDater: (daters, action) => {
            let other_daters = daters.filter(item => item.id !== action.payload.dater.id)
            return [...other_daters, action.payload.daters]
        },
    
        deleteDater: (daters, action) => {
            return daters.filter(item => ( item.id !== action.payload.id ))
        },

        flushDater: (daters) => {
            return []
        }
    },
    extraReducers(builder) {
        builder
            .addCase(DBcreateDater.fulfilled, (daters, action) => {
                return [...daters, action.payload]
                })
            .addCase(DBupdateDater.fulfilled, (daters, action) => {
                let other_daters = daters.filter(item => item.id !== action.payload.id)
                return [...other_daters, action.payload]
                })
            .addCase(DBdeleteDater.fulfilled, (daters, action) => {
                return daters.filter(item => item.id !== action.payload)
                })
            .addCase(DBrequestDaters.fulfilled, (daters, action) => {
                return action.payload
                })
    }
    
});

export const { actions: StateActions, reducer: StateReducer } = DatersSlice 

export const { createDater, updateDater, deleteDater, flushDater } = StateActions

export default DatersSlice.reducer