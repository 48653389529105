import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type CustomError = {
	message: string
}

export type User = {
	id: string,
    email?: string | undefined,
	// @TODO: you can add supabase others User fields
}

export enum RequestStatus {
	IDLE = "IDLE",
	LOADING = "LOADING",
	COMPLETED = "COMPLETED",
	FAILED = "FAILED",
}


export enum AuthCallTypes {
	SIGN_IN = "signInStatus",
	SIGN_UP = "signUpStatus",
	SIGN_OUT = "signOutStatus",
}


export type Session = {
	access_token: string
	token_type: string
	expires_in: number
	refresh_token: string
	user: User
	expires_at?: number
}


export interface AuthState {
	session: Session | null
	status: RequestStatus
}

export const initialState: AuthState = {
	session: null,
	status: RequestStatus.IDLE,
}

const AuthSlice = createSlice({
    name: "AuthState",
    initialState: initialState,
    reducers: {
        startCall: (
            authState: AuthState,
            { payload }: PayloadAction<{ callType: AuthCallTypes }>
        ) => {
            authState.status = RequestStatus.LOADING
        },
        signIn: (
            authState: AuthState,
            {
                payload,
            }: PayloadAction<{ session: Session | null; error: CustomError | null }>
        ) => {
            authState.session = payload.session
            if (payload.error) { throw payload.error}
            // console.log('Erreur dans SignIn de AuthSlice', payload.error)
    
            authState.status = payload.error
                ? RequestStatus.FAILED
                : RequestStatus.COMPLETED
        },
        signUp: (
            authState: AuthState,
            { payload }: PayloadAction<{ error: CustomError | null }>
        ) => {
            if (payload.error) { throw payload.error}
            // console.log('Erreur dans signUp de AuthSlice', payload.error)

            authState.status = payload.error
                ? RequestStatus.FAILED
                : RequestStatus.COMPLETED
        },
        signOut: (
            authState: AuthState,
            { payload }: PayloadAction<{ error: CustomError | null }>
        ) => {
            if (payload.error) { throw payload.error}
            // console.log('Erreur dans signOut de AuthSlice', payload.error)

            if (!payload.error) {
                authState.status = RequestStatus.COMPLETED
                authState.session = null
    
                return
            }
    
            authState.status = RequestStatus.FAILED
        },
        setLoggedInUserWithLocalData: (
            session: AuthState,
            { payload }: PayloadAction<{ localSessionData: Session }>
        ) => {
            session.session = payload.localSessionData
        },
    }
    
  });
  
export const { actions: SessionActions, reducer: SessionReducer } = AuthSlice 
  
export const { startCall, signIn, signUp, signOut, setLoggedInUserWithLocalData } = SessionActions
  
export default AuthSlice.reducer