import { Col, Row } from "react-bootstrap";

import { TypeProposition } from "types/Propositions";

export const TextPropositions = ({
  propositions,
}: {
  propositions: TypeProposition[];
}) => {
  // const { data: propositions } = useGetPropositionsOfMeetingQuery(id_meeting);

  const format_time = (time: Date) => {
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const TextEvent = ({ event }: { event: TypeProposition }) => {
    //     if self.start.date() == self.end.date():
    //     return "le {} de {} à {} with {}={}".format(self.start.strftime('%a %d %b'), self.start.strftime('%H:%M'), self.end.strftime('%H:%M'), self.value_name, self.value)
    // else:
    //     return "du {} au {} with {}={}".format(self.start.strftime('%a %d %b %HH%M'), self.end.strftime('%a %d %b %HH%M'), self.value_name, self.value)

    let start = event.start
      ? new Date(event.start.toLocaleString())
      : new Date();
    let end = event.end ? new Date(event.end.toLocaleString()) : new Date();

    if (start.getDate() === end.getDate()) {
      return (
        <Row key={start.toLocaleString() + end.toLocaleString()}>
          {" "}
          - le {start.toLocaleDateString()} entre {format_time(start)} et{" "}
          {format_time(end)}{" "}
        </Row>
      );
    }
    return (
      <Row
        style={{ display: "flex", justifyContent: "left" }}
        key={start?.toLocaleString() + end.toLocaleString()}
      >
        {" "}
        - entre le {start?.toLocaleDateString()} à {start.toLocaleTimeString()}{" "}
        et le {end.toLocaleDateString()} à {end.toLocaleTimeString()}{" "}
      </Row>
    );
  };

  const BlocPropositionOui = ({
    propositions,
  }: {
    propositions: TypeProposition[];
  }) => {
    if (!propositions || propositions?.length === 0) {
      return null;
    }
    return (
      <Row style={{ margin: "5px" }}>
        <p style={{ display: "flex", justifyContent: "flex-start" }}>
          Je suis disponible :{" "}
        </p>
        <div style={{ margin: "2px", marginLeft: "10px" }}>
          {propositions?.map((prop: TypeProposition) => {
            if (prop.availability === 1) {
              return <TextEvent key={prop.id} event={prop}></TextEvent>;
            }
            return null as never;
          })}
        </div>
      </Row>
    );
  };

  const BlocPropositionEventuelle = ({
    propositions,
  }: {
    propositions: TypeProposition[];
  }) => {
    if (!propositions || propositions?.length === 0) {
      return null;
    }
    return (
      <Row style={{ margin: "0px" }}>
        <p
          style={{
            display: "flex",
            justifyContent: "flex-start",
            textAlign: "left",
          }}
        >
          Cela n'a pas ma préférence mais je pourrai me rendre disponible :{" "}
        </p>
        <div style={{ margin: "2px", marginLeft: "10px" }}>
          {propositions?.map((prop: TypeProposition) => {
            if (prop.availability < 1 && prop.availability > 0) {
              return <TextEvent key={prop.id} event={prop}></TextEvent>;
            }
            return null as never;
          })}
        </div>
      </Row>
    );
  };

  if (!propositions || propositions?.length === 0) {
    return null;
  }
  return (
    <>
      <Col>
        <h4>Récapitulatif</h4>
        <BlocPropositionOui
          propositions={propositions?.filter(
            (prop: TypeProposition) => prop.availability === 1
          )}
        />

        {/* {propositions?.filter(
          (prop: TypeProposition) =>
            prop.availability < 1 && prop.availability > 0
        ).length > 0 ? (
          <Row>
            <br />
            <p
              style={{
                textAlign: "left",
              }}
            >
              Si aucun de ces créneaux, ayant ma préférence pouvait convenir, la
              réunion pourrait également être fixées sur les plages suivantes :
            </p>
          </Row>
        ) : null} */}

        <BlocPropositionEventuelle
          propositions={propositions?.filter(
            (prop: TypeProposition) =>
              prop.availability < 1 && prop.availability > 0
          )}
        />
      </Col>
    </>
  );
};
