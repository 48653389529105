import { INITIAL_EVENTS } from '../data/events_init'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { TypeEvent } from '../types/DaTimeEvents';

const stateSlice = createSlice({
    name: "eventSlice",
    initialState: INITIAL_EVENTS,
    reducers: {
        setEvents: (events, action: {payload: TypeEvent[]}) => {
            return action.payload
        },
        addEvent: (events, action: {payload: TypeEvent}) => {
            return [...events, action.payload]
        },
        updateEvent: (events, action: {payload: TypeEvent}) => {
            let other_events = events.filter(item => item.id !== action.payload.id)
            return [...other_events, action.payload]
        },
        deleteEvent: (events, action: {payload: string }) => {
            return events.filter(item => item.id !== action.payload)
        },
        deleteRoutine: (events, action: {payload: string }) => {
            return events.filter(item => item.resourceId !== action.payload)
        },
        flushEvents: (events) => {
            return []
        }
    },
});

export const { actions: StateActions, reducer: StateReducer } = stateSlice 

export const { setEvents, addEvent, updateEvent, deleteEvent, deleteRoutine, flushEvents } = StateActions
