
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { supabase } from '../components/auth/supabaseClient';
import type { TypeLunch } from '../types/Lunch';

const default_lunch : TypeLunch = {
    on: true, 
    name: "dejeuner",
    startTime: "12:30", endTime: "14:00", 
    unavailabilty_rate: 1, 
    daysOfWeek: [0, 1, 2, 3, 4, 5, 6]} 
const default_embauche = "08:00" as string
const default_debauche = "19:00" as string

const lunchSlice = createSlice({
    name: "lunchSlice",
    initialState: default_lunch,
    reducers: {
        setLunch: (lunch, action: {payload: TypeLunch}) => {
            return action.payload
        },
        resetLunch: (lunch) => {
            return default_lunch
        },
    },
});

export const { actions: LunchActions, reducer: LunchReducer } = lunchSlice 
export const { setLunch, resetLunch } = LunchActions



const embaucheSlice = createSlice({
    name: "embaucheSlice",
    initialState: default_embauche,
    reducers: {
        setEmbauche: (embauche, action: {payload: string}) => {
            return action.payload
        },
        resetEmbauche: (embauche) => {
            return default_embauche
        },
    },
});

export const { actions: EmbaucheActions, reducer: EmbaucheReducer } = embaucheSlice 
export const { setEmbauche, resetEmbauche } = EmbaucheActions


const debaucheSlice = createSlice({
    name: "debaucheSlice",
    initialState: default_debauche,
    reducers: {
        setDebauche: (debauche, action: {payload: string}) => {
            return action.payload
        },
        resetDebauche: (debauche) => {
            return default_debauche
        },
    },
});

export const { actions: DebaucheActions, reducer: DebaucheReducer } = debaucheSlice 
export const { setDebauche, resetDebauche } = DebaucheActions