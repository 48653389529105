import { DateSelectArg, EventApi } from "@fullcalendar/core";
import { createId } from "../api/createId";
import { TypeEvent } from "../types/DaTimeEvents";
import { TypeProposition } from "../types/Propositions";

export const from_date_to_Event = (
  temp_event: DateSelectArg,
  user_id: string | undefined
) => {
  let new_event: TypeEvent = {
    type: "event",
    start: temp_event.startStr,
    end: temp_event.endStr,
    id: createId(),
    allDay: temp_event.allDay,
    title: "indisponibilité",
    // id_meeting: [],
    SharedWith: [],
    user_id: user_id ? user_id : null,
    lieu: null,
    transport: null,
    rappel: null,
    priority: 1,
    days_of_week: [],
    resourceId: null,
  };
  return new_event;
};

export const from_date_to_Proposition = (
  temp_event: DateSelectArg,
  user_id: string,
  id_meeting: string
) => {
  let new_event: TypeProposition = {
    start: temp_event.startStr,
    end: temp_event.endStr,
    id: createId(),
    id_meeting: id_meeting, // createId
    submitted_to: [user_id],
    validated_by: [user_id],
    possible_by: [],
    rejected_by: [],
    availability: 1,
    is_new: true,
  };
  return new_event;
};

export const from_EventApi_to_Event = (
  temp_event: EventApi,
  user_id: string | undefined
) => {
  let new_event: TypeEvent = {
    start: temp_event.startStr,
    type: temp_event.extendedProps.type
      ? temp_event.extendedProps.type
      : "event",
    end: temp_event.endStr,
    id: temp_event.id ? temp_event.id : createId(),
    allDay: temp_event.allDay,
    title: temp_event.title,
    // id_meeting: [],
    SharedWith: [],
    user_id: user_id ? user_id : null,
    lieu: temp_event.extendedProps.lieu ? temp_event.extendedProps.lieu : null,
    transport: temp_event.extendedProps.transport
      ? temp_event.extendedProps.transport
      : null,
    rappel: temp_event.extendedProps.rappel
      ? temp_event.extendedProps.rappel
      : null,
    priority: temp_event.extendedProps.priority
      ? temp_event.extendedProps.priority
      : 1,
    days_of_week: temp_event.extendedProps.days_of_week
      ? temp_event.extendedProps.days_of_week
      : [],
    resourceId: temp_event.extendedProps.resourceId
      ? temp_event.extendedProps.resourceId
      : null,
  };
  return new_event;
};

export const from_EventApi_to_Proposition = (
  temp_proposition: EventApi,
  user_id: string
) => {
  let new_proposition: TypeProposition = {
    start: temp_proposition.startStr,
    end: temp_proposition.endStr,
    id: temp_proposition.id ? temp_proposition.id : createId(),
    id_meeting: temp_proposition.extendedProps.id_meeting
      ? temp_proposition.extendedProps.id_meeting
      : "probleme",
    availability:
      "availability" in temp_proposition.extendedProps
        ? temp_proposition.extendedProps["availability"]
        : 1,
    submitted_to: temp_proposition.extendedProps.submitted_to
      ? temp_proposition.extendedProps.submitted_to
      : [user_id],
    validated_by: temp_proposition.extendedProps.validated_by
      ? temp_proposition.extendedProps.validated_by
      : [user_id],
    possible_by: temp_proposition.extendedProps.possible_by
      ? temp_proposition.extendedProps.possible_by
      : [],
    rejected_by: temp_proposition.extendedProps.rejected_by
      ? temp_proposition.extendedProps.rejected_by
      : [],
    is_new: temp_proposition.extendedProps.is_new
      ? temp_proposition.extendedProps.is_new
      : false,
  };
  return new_proposition;
};

export const from_Proposition_to_Event = (
  proposition: TypeProposition,
  user_id: string
): TypeEvent => {
  let { availability, ...prop_to_save } = proposition;

  let new_event: TypeEvent = {
    id: createId(),
    title: "Impossibilité entrée via DaTime",
    start: proposition.start,
    end: proposition.end,
    type: "event",
    user_id: user_id,
    priority: 1 - proposition.availability,
    SharedWith: [],
    allDay: false,
    lieu: null,
    transport: null,
    rappel: null,
    days_of_week: [],
    resourceId: null,
  };
  return new_event;
};
