import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { postChronopt } from "api/postChronopt";
import { downloadBlob } from "components/ics/utils";
import { propositions_to_ics } from "format/to_ics";
import ImportAgenda from "modules/import_other_agenda";
import { TextPropositions } from "modules/TextPropositions";
import { FirstVisitModal } from "pages/invited/modal_first_visit";
import { useGetMeetingByIdQuery } from "store/features/meetings";
import {
  useGetMyMeetingByIDQuery,
  useUpdateParticipantsMutation,
} from "store/features/participants";
import {
  useCreatePropositionMutation,
  useGetPropositionsOfMeetingQuery,
} from "store/features/propositions";
import { intialize_user_opinion } from "tools/change_user_opinion";
import { TypeEvent } from "types/DaTimeEvents";
import { TypeProposition } from "types/Propositions";
import { InvitedCalendar } from "./calendar_invited";
import { OnLeaveInvitedModal } from "./ModalLeave";
import { ButtonSendProposition } from "./SendPropositionsButton";

export const InvitedPage = () => {
  // pour l'instant, on affiche juste le texte
  // pour en faire une disponibilité on met dans une base à côté

  const { id_meeting, id_person } = useParams();

  const [createProposition] = useCreatePropositionMutation();
  const [modalOn, setModalOn] = useState(false);
  const [modalLeave, setModalLeave] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const handleClose = () => setModalOn(false);

  const [invitedEvents, setInvitedEvents] = useState<TypeEvent[]>([]);
  const [propositionDaTime, setPropositionDaTime] = useState<TypeProposition[]>(
    []
  );
  const [proposMeeting, setProposMeeting] = useState<TypeProposition[]>([]);

  const { data: MeetingPage } = useGetMeetingByIdQuery(id_meeting);
  const { data: propositions } = useGetPropositionsOfMeetingQuery(id_meeting);

  const [updateParticipant] = useUpdateParticipantsMutation();
  const { data: invited } = useGetMyMeetingByIDQuery({
    id_meeting,
    id_person,
  });

  useEffect(() => {
    if (propositions && id_person) {
      let proposition_meeting = propositions.filter(
        (propos) => propos.id_meeting === id_meeting
      );
      let with_user_by_default = proposition_meeting.map((prop) => {
        return intialize_user_opinion(prop, id_person);
      });
      setProposMeeting(with_user_by_default);
    }
  }, [propositions, id_meeting, id_person]);

  useEffect(() => {
    if (invited) {
      updateParticipant({ ...invited, connected_date: new Date() });
    }
  }, [invited]);

  const ButtonIcs = () => {
    return (
      <Button
        style={{ padding: "8px", margin: "10px" }}
        disabled={proposMeeting?.length === 0}
        onClick={() => {
          // on pourrait ajouter MeetingPage pour récupérer le nom comme on le fait dans la page DaTimer mais non
          // il faut régler les RLS de supabase d'abord
          if (proposMeeting) {
            const url = propositions_to_ics(
              proposMeeting.filter((item) => item.availability > 0),
              "option via DaTime"
            );
            const blob: Blob = new Blob([url], {
              type: "text/calendar;charset=utf-8",
            });
            const filename = "options-datime.ics";
            downloadBlob(blob, filename);
          }
        }}
      >
        Ajouter les options à mon agenda
      </Button>
    );
  };

  return (
    <>
      <FirstVisitModal></FirstVisitModal>
      <OnLeaveInvitedModal
        footer={<ButtonIcs />}
        modalOn={modalLeave}
        handleClose={() => setModalLeave(false)}
      />
      <br />
      <Row>
        <h2>Entrez vos disponibilités </h2> <br />
        <h3>pour prendre le rendez-vous qui vous convient </h3>
        <p style={{ backgroundColor: "rgba(240, 50, 90, 0.5)" }}>
          Toutes les informations que vous entrez ici ne sont connues que de
          vous et ne sont pas partagées{" "}
        </p>
      </Row>
      <hr />
      <Row>
        <h3>
          Cliquer une fois pour dire que le créneau n'a pas votre préférence,
          deux fois pour dire qu'il ne vous convient pas{" "}
        </h3>
        <hr />
      </Row>
      <Row>
        <Col className="align-items-center" sm={8}>
          {id_person && id_meeting ? (
            <InvitedCalendar
              id_user={id_person}
              id_meeting={id_meeting}
              proposMeeting={proposMeeting}
              setProposMeeting={setProposMeeting}
              events_sup={invitedEvents}
            ></InvitedCalendar>
          ) : null}
        </Col>
        <Col>
          <Row>
            {id_person ? (
              <ButtonSendProposition
                propositions={proposMeeting}
                id_user={id_person}
                on_end={() => {
                  if (invited) {
                    console.log("ici");
                    updateParticipant({ ...invited, answer_date: new Date() });
                  }
                  setModalLeave(true);
                  if (id_meeting) {
                    postChronopt(id_meeting, "next");
                  }
                }}
              />
            ) : (
              <ButtonSendProposition
                propositions={proposMeeting}
                id_user="fake_user"
                on_end={() => {}}
              />
            )}
          </Row>

          <Row className="align-items-center">
            <br /> <br /> <br /> <br />
            <br /> <br />
            <hr />
            <Button
              style={{ marginTop: "5px" }}
              onClick={() => {
                console.log("Bien définir les créneaux que l'on veut retirer");
              }}
            >
              Réinitialiser
            </Button>
          </Row>

          <Row
            style={{
              display: "flex",
              backgroundColor: "grey",
              alignItems: "center",
              justifyContent: "center",
              margin: "5px",
            }}
          >
            {id_meeting ? (
              <TextPropositions propositions={propositionDaTime} />
            ) : null}
          </Row>
          {id_person ? <ImportAgenda user_id={id_person} /> : null}
        </Col>
      </Row>
    </>
  );
};
