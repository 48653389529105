import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { User } from "@supabase/supabase-js";
import { supabase } from "components/auth/supabaseClient";

export const supabaseUserApi = createApi({
  reducerPath: "supabaseUserApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      queryFn: async () => {
        const { data, error } = await supabase.auth.getUser();
        if (error) {
          return { error };
        }
        return { data: data["user"] };
      },
      providesTags: ["User"],
    }),
    getUserId: builder.query<string, void>({
      queryFn: async () => {
        const { data, error } = await supabase.auth.getUser();
        if (error) {
          return { error };
        }
        return { data: data["user"]["id"] };
      },
      providesTags: ["User"],
    }),
  }),
});

export const { useGetUserQuery, useGetUserIdQuery } = supabaseUserApi;
