import { useEffect } from "react";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "../store/store";

import { Button } from "react-bootstrap";

// MSAL imports
import { PopupRequest } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { createId } from "api/createId";
import { useCreateEventMutation } from "store/features/events";
import { useGetUserIdQuery } from "store/features/user";
import { TypeEvent } from "types/DaTimeEvents";
import { msalInstance } from "./OutlookClient";

const styleElement = {
  backgroundColor: "rgba(7, 124, 240, 0.1)",
  justifyContent: "center",
  alignItems: "center",
};

export const loginRequest: PopupRequest = {
  scopes: ["Calendars.ReadBasic"], // ["User.Read"]
};

const ButtonOulook = () => {
  const { instance, accounts, inProgress } = useMsal();

  if (accounts.length > 0) {
    return <p>There are currently {accounts.length} users signed in!</p>;
  } else if (inProgress === "login") {
    return <p>Login is currently in progress!</p>;
  } else {
    return (
      <>
        {/* <p>There are currently no users signed in!</p> */}
        <Button onClick={() => instance.loginPopup(loginRequest)}>
          Importer un agenda outlook
        </Button>
      </>
    );
  }
};

const callMsGraph = async (what_to_do_with_item: (item: any) => void) => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }

  const responseLogIn = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account,
  });

  const headers = new Headers();
  const bearer = `Bearer ${responseLogIn.accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Prefer", `outlook.timezone="Eastern Standard Time"`);
  headers.append("Content-Type", "application/json");
  // Content-Type: application/json
  const options = {
    method: "GET",
    headers: headers,
  };
  const fields = ["subject,body,bodyPreview,organizer,start,end,location"];
  // avec calendars, on peut repérer le calendar car il a isDefaultCalendar à true
  await fetch(
    `https://graph.microsoft.com/v1.0/me/events?$select=${fields.join()}`,
    options
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      json["value"].forEach((item: any) => {
        console.log("un event outlook", item);
        what_to_do_with_item(item);
      });
    });
};

const DisconnectOulook = () => {
  const { instance, accounts, inProgress } = useMsal();

  return (
    <>
      <Button onClick={() => instance.logoutPopup()}>LogOut</Button>
    </>
  );
};

const CalendarOulook = () => {
  const { data: user_id } = useGetUserIdQuery();

  const dispatch = useDispatch<AppDispatch>();
  const [createEvent] = useCreateEventMutation();

  const outlookEventToFullCalendar = (item: any): TypeEvent => {
    return {
      start: item.start.dateTime || item.start.date, // try timed. will fall back to all-day
      end: item.end.dateTime || item.end.date, // same
      type: "event",
      id: createId(), // should be related to item.id,
      allDay: false,
      title: item.subject,
      // id_meeting: [],
      SharedWith: [],
      lieu: item.location,
      user_id: user_id ? user_id : "",

      transport: null,
      rappel: null,
      priority: 1,
      days_of_week: [],
      resourceId: null,
      // extendedProps: (item.extendedProperties || {}).shared || {},
    };
  };

  useEffect(() => {
    callMsGraph((item: any) => {
      let fc_item = outlookEventToFullCalendar(item);
      createEvent(fc_item);
    });
  }, []);

  return (
    <>
      <p> Les données de votre agenda sont utilisées </p>
    </>
  );
};

const OutlookImport = ({ user_id }: { user_id: string }) => {
  return (
    <>
      {/* <script src='https://apis.google.com/js/api.js' onLoad={gapiLoaded()} async={true} > </script> */}
      <div style={{ ...styleElement, borderRadius: "25px" }}>
        <MsalProvider instance={msalInstance}>
          <AuthenticatedTemplate>
            <CalendarOulook></CalendarOulook>
            <Button
              onClick={() =>
                callMsGraph((item: any) =>
                  console.log("item from calendar", item)
                )
              }
            >
              {" "}
              Synchroniser
            </Button>
            <DisconnectOulook></DisconnectOulook>
            {/* <p>At least one account is signed in!</p> */}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <ButtonOulook></ButtonOulook>
            {/* <p>No users are signed in!</p>
				<Example></Example> */}
          </UnauthenticatedTemplate>
        </MsalProvider>
        {/* <p>Ce n'est pas forcément l'agenda associé à votre compte supabase</p> */}
      </div>
    </>
  );
};

export default OutlookImport;
