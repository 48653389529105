import { useNavigate, useParams } from "react-router-dom";

import { Button } from "react-bootstrap";

import { useGetMeetingByIdQuery } from "store/features/meetings";
import { useGetMyMeetingByIDQuery } from "store/features/participants";
import { useGetUserQuery } from "store/features/user";
import { TableParticipantsInvitation } from "./TableParticipantsInvitation";

export const InvitationsPage = () => {
  let navigate = useNavigate();
  const { id_meeting } = useParams();

  const { data: connectedUser } = useGetUserQuery();
  const { data: meetingPage } = useGetMeetingByIdQuery(id_meeting);
  const { data: myMeeting } = useGetMyMeetingByIDQuery({
    id_meeting,
    id_person: connectedUser?.id,
  });

  return (
    <>
      <br />
      <h1> Il ne reste plus qu'à lancer les invitations ! </h1>
      <br />
      <h2> Contactez les participants comme vous le souhaitez </h2>
      <br />
      <br />
      <p></p>
      {meetingPage ? (
        <div>
          On parle ici des participants à la réunion devant durer{" "}
          {meetingPage["duration"]} minute ayant pour nom public{" "}
          <strong> {meetingPage["object"]}</strong>{" "}
          {myMeeting ? (
            myMeeting["my_object"] !== meetingPage["object"] ? (
              <p>et qui est pour vous {myMeeting["my_object"]} </p>
            ) : null
          ) : null}
          <br />
          Si vous souhaitez revenir aux paramètres de la réunions, vous pouvez
          le faire en cliquant ici :
          <Button
            size="sm"
            onClick={() => {
              navigate("/meeting/edit/" + meetingPage["id"]);
            }}
          >
            {" "}
            Modifier{" "}
          </Button>
        </div>
      ) : null}
      <br />
      <TableParticipantsInvitation
        connectedUser={connectedUser}
        id_meeting={id_meeting}
        duration_global={meetingPage ? meetingPage["duration"] : 60}
      />
      <Button
        onClick={() => {
          navigate("/my-meetings");
        }}
      >
        {" "}
        Revenir aux réunions{" "}
      </Button>
    </>
  );
};
