export const durations = [
  { value: "15", label: "15 minutes", minutes: 15 },
  { value: "20", label: "20 minutes", minutes: 20 },
  { value: "30", label: "30 minutes", minutes: 30 },
  { value: "45", label: "45 minutes", minutes: 45 },
  { value: "60", label: "1 heure", minutes: 60 },
  { value: "90", label: "1 heure 30", minutes: 90 },
  { value: "120", label: "2 heures", minutes: 120 },
  { value: "150", label: "2 heures 30", minutes: 150 },
  { value: "180", label: "3 heures", minutes: 180 },
] as const;
