import { postAgendIAGetCreneau, requestDataType } from "api/agendIA";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MyCalendar } from "./fullCalendar";

import { useGetUserIdQuery } from "store/features/user";

import ImportAgenda from "modules/import_other_agenda";
import { useCreateEventMutation } from "store/features/events";
import {
  useCreatePropositionMutation,
  useDeletePropositionMutation,
} from "store/features/propositions";
import { ExcludedSlotsSelector } from "store/selectors";
import { TypeProposition } from "types/Propositions";

export const MyAgenda = () => {
  const { data: user_id } = useGetUserIdQuery();
  const [createEvent] = useCreateEventMutation();
  const [createProposition] = useCreatePropositionMutation();
  const [deleteProposition] = useDeletePropositionMutation();

  const bh = useSelector(ExcludedSlotsSelector);
  const [propositionDaTime, setPropositionDaTime] = useState<TypeProposition[]>(
    []
  );

  const onRequest = async () => {
    const data_to_send = {
      userId: user_id,
      duree: 60,
      plage: 12,
      nb_choices: 5,
      excluded_slots: bh,
      excluded_days: [],
    } as requestDataType;

    await postAgendIAGetCreneau(data_to_send).then((reponse_json) => {
      if (reponse_json) {
        setPropositionDaTime(reponse_json);
      }
    });
  };

  return (
    <div>
      <h1> En chantier : qu'attend-on de cette page exactement ?</h1>
      <p> un aperçu de ce que fait DaTime ? </p>

      <h3> ajouter l'import de calendrier </h3>

      <Row style={{ margin: "10px" }}>
        <h2>Entrez vos disponibilités facilement </h2>
        <h3>pour prendre le rendez-vous qui vous convient </h3>
        <p style={{ backgroundColor: "rgba(240, 50, 90, 0.5)" }}>
          Toutes les informations que vous entrez ici ne sont connues que de
          vous et ne sont pas partagées{" "}
        </p>
      </Row>

      <Row style={{ margin: "10px" }}>
        {" "}
        Vous pouvez ici supprimer les créneaux qui ont été ajouté via DaTime.
        Pour ceux, issus d'un autre agenda, il faut modifier la source refaire
        une synchronisation.
      </Row>

      <Row style={{ margin: "10px" }}>
        <Col className="align-items-center" sm={8}>
          {user_id ? (
            <MyCalendar
              id_user={user_id}
              events_sup={propositionDaTime}
            ></MyCalendar>
          ) : null}
          <br />
          <Row>
            <br />
            {/* <ButtonSearchCreneau /> */}
          </Row>
        </Col>
        <Col className="align-items-center">
          <Row className="align-items-center"></Row>

          <Button
            id="send_to_agendIA"
            onClick={onRequest}
            disabled={propositionDaTime.length > 0}
          >
            <p>
              <strong>
                {" "}
                Voir les créneaux d'une heure que pourrait proposer DaTime{" "}
              </strong>
            </p>
          </Button>
          <p>
            {" "}
            Si vous voulez bloquer un créneau vous pouvez, ajouter un élément
            dans le calendrier directement ici. Cela sera retenu pour l'ensemble
            de vos réunions à venir
          </p>
          <Button
            style={{ margin: "10px" }}
            id="reset"
            onClick={() => setPropositionDaTime([])}
            disabled={propositionDaTime.length === 0}
          >
            <p data-align="right" data-background="red">
              <strong> Effacer les créneaux </strong>
            </p>
          </Button>
          {user_id ? <ImportAgenda user_id={user_id} /> : null}
        </Col>
      </Row>

      {/* <SelectContraintes />
      <SelectPreferences /> */}
    </div>
  );
};
