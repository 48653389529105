import { make_calendar_from_events } from "components/ics/utils";
import { TypeProposition } from "types/Propositions";

function iso_to_ics_date_format(isodate: string): string {
  return isodate.replace(/[-:]/g, "").split(".")[0] + "Z";
}

function formatDate(date: Date): string {
  return iso_to_ics_date_format(date.toISOString());
}

const proposition_to_ics = (event: TypeProposition, name: string): string => {
  return `BEGIN:VEVENT
DTSTAMP:${formatDate(new Date())}
DTSTART:${iso_to_ics_date_format(event.start)}
DTEND:${iso_to_ics_date_format(event.end)}
UID:DaTime_${event.id}
STATUS:TENTATIVE
CATEGORIES:DaTime Proposition
SUMMARY:${name}
END:VEVENT`;
};
//UID=${event.id.toString().toUpperCase()}

export const propositions_to_ics = (
  events: TypeProposition[],
  name: string
): string => {
  const bodies = events.map((event) => {
    return proposition_to_ics(event, name);
  });
  return make_calendar_from_events(bodies);
};
