import { BusinessHoursInput, EventInput } from "@fullcalendar/core";
import { createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";

import { getHashValues } from "./utils";

import { InvitedMeetingType } from "../types/Dater";
import { TypeEvent } from "../types/DaTimeEvents";
import { TypeLunch } from "../types/Lunch";
import { MeetingType, SimpleKindType } from "../types/Meeting";
import { TypeProposition } from "../types/Propositions";
import type { RequestStatus, Session } from "./SessionReducer";
import type { RootState } from "./store";

export const WeekendSelector = (state: RootState) => state.weekendsVisible;
export const DaySlotsSelector = (state: RootState) => state.dayslots;
export const DatersSelector = (state: RootState) =>
  state.daters.length > 0 ? (state.daters[0] ? state.daters : null) : null;

// export const KindOfRdvSelector = (state: RootState) => state.kindOfRdv;
// export const idOfKindOfRdvSelector = (state: RootState) =>
//   state.kindOfRdv.map((item) => {
//     return item.value;
//   });

export const EventsSelector = (state: RootState) => state.events;
export const UserIdSelector = (state: RootState) => state.auth.session?.user.id;
export const MeetingSelector = (state: RootState): MeetingType => state.meeting;
export const InvitedSelector = (state: RootState) => state.invited;

export const LunchSelector = (state: RootState) => state.lunch;
export const EmbaucheSelector = (state: RootState) => state.embauche;
export const DebaucheSelector = (state: RootState) => state.debauche;

export const MyMeetingSelector = createSelector(
  InvitedSelector,
  UserIdSelector,
  (invited: InvitedMeetingType[], id: string | undefined) => {
    if (id) {
      const my_meeting = invited.filter((item) => item.id_person === id);
      return my_meeting[0];
    } else {
      return invited[0];
    }
  }
);

export const RoutinesSelector = createSelector(
  (state: RootState) => state.events,
  (items) => {
    // l'idée iy est mais ça ne marche pas
    const routines = items.filter((item) => {
      if ("days_of_week" in item) {
        return item["days_of_week"] !== null;
      } else {
        return false;
      }
    });
    console.log("dans le routine selector", routines);
    return routines;
  }
);

export const SessionSelector = (state: RootState): Session | null =>
  state.auth.session;
export const AuthStatusSelector = (state: RootState): RequestStatus =>
  state.auth.status;

function eventsToWeeklyProposition(hash: any): EventInput[] {
  // on a besoin d'avoir un évenement par jour de la semaine
  // d'une manière ou d'un autre, il faudrait faire un GroupId pour conserver l'origine
  const valeurs = getHashValues(hash);
  const valeurs2 = valeurs.filter(
    (event) => event.resourceId || event.days_of_week
  );
  // const flatten_events = oneEventPerDay(valeurs2)
  const valeurs4 = valeurs2.map((event) => {
    return recurringEventToTimeline(event);
  });
  return valeurs4;
}

function recurringEventToTimeline(event: any): EventInput {
  const todayDay = new Date().getDay().toString();
  // console.assert(event.days_of_week.length() == 1, "Il ne devrait y avoir qu'un seul element à ce stade")
  const resourceIds = event.days_of_week.map((day: numberOfDay) => {
    return "Day" + day;
  });
  const dispo = {
    ...event,
    resourceIds: resourceIds,
  };
  dispo.days_of_week = [todayDay];
  return dispo;
}

export const EventsFCSelector = createSelector(
  (state: RootState) => state.events,
  (items: TypeEvent[]) => {
    const fc_events = items.map((event: TypeEvent) => {
      let fc_event = { ...event } as EventInput;
      fc_event["daysOfWeek"] = null;
      if (fc_event["days_of_week"]) {
        fc_event["daysOfWeek"] =
          fc_event["days_of_week"].length > 0 ? fc_event["days_of_week"] : null;
      }
      return fc_event;
    });
    // console.log('sortie de EventsFCSelector', fc_events)
    return fc_events;
  }
);

export const OrderedEventsOnlySelector = createSelector(
  (state: RootState) => state.events,
  (items: TypeEvent[]) => {
    const fc_events = items.filter((event: TypeEvent) => {
      if (event.type == "event") {
        return event;
      }
    });
    console.log("sortie du filtre ", fc_events);
    const sorted_event = fc_events.sort(
      (a, b) => Date.parse(a.start) - Date.parse(b.start)
    );
    console.log("sortie de OrderedEventsFCSelector", sorted_event);
    return sorted_event;
  }
);



export const MinTimeSelector = createSelector(
  (state: RootState) => state.meeting["simpleType"],
  (state: RootState) => state.lunch,
  (state: RootState) => state.embauche,
  (state: RootState) => state.debauche,
  (
    typeMeeting: SimpleKindType,
    lunch: TypeLunch,
    embauche: string,
    debauche: string
  ) => {
    if (typeMeeting === "dej") {
      return lunch["startTime"];
    }
    if (typeMeeting === "soirée") {
      return debauche;
    }
    return embauche;
  }
);

export const MaxTimeSelector = createSelector(
  (state: RootState) => state.meeting["simpleType"],
  (state: RootState) => state.lunch,
  (state: RootState) => state.embauche,
  (state: RootState) => state.debauche,
  (
    typeMeeting: SimpleKindType,
    lunch: TypeLunch,
    embauche: string,
    debauche: string
  ) => {
    if (typeMeeting === "dej") {
      return lunch["endTime"];
    }
    if (typeMeeting === "soirée") {
      return "23:59";
    }
    return debauche;
  }
);

export const BusinesHoursSelector = createSelector(
  (state: RootState) => state.meeting["simpleType"],
  (state: RootState) => state.lunch,
  (state: RootState) => state.embauche,
  (state: RootState) => state.debauche,
  (
    typeMeeting: SimpleKindType,
    lunch: TypeLunch,
    embauche: string,
    debauche: string
  ) => {
    let BH = [
      // specify an array instead
      {
        name: "non travaillé",
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday, Tuesday, Wednesday
        startTime: embauche, // 8am
        endTime: debauche, // 6pm
      },
    ];
    if (typeMeeting === "normal") {
      let BH = [
        // specify an array instead
        {
          name: lunch["name"] ? lunch["name"] : "",
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday, Tuesday, Wednesday
          startTime: embauche, // 8am
          endTime: lunch["startTime"], // 6pm
        },
        {
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday, Tuesday, Wednesday
          startTime: lunch["endTime"],
          endTime: debauche,
        },
      ];
      return BH as BusinessHoursInput[];
    }

    if (typeMeeting === "dej") {
      let BH = [
        // specify an array instead
        {
          name: lunch["name"] ? lunch["name"] : "",
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday, Tuesday, Wednesday
          startTime: lunch["startTime"],
          endTime: lunch["endTime"],
        },
      ];
      return BH as BusinessHoursInput[];
    }

    if (typeMeeting === "soirée") {
      let BH = [
        // specify an array instead
        {
          name: lunch["name"] ? lunch["name"] : "",
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday, Tuesday, Wednesday
          startTime: debauche,
          endTime: "23:59",
        },
      ];
      return BH as BusinessHoursInput[];
    }

    return BH as BusinessHoursInput[];
  }
);

const convert_time_to_utc = (local_time: string): string => {
  // option 1:  elle sera à privilégier pour ne pas avoir à charger luxon
  // let timezoneOffset = new Date().getTimezoneOffset();
  // console.log("pour voir le timezoneOffset", timezoneOffset)
  // option 2:
  const dt = DateTime.fromISO(local_time);
  // console.log(local_time, dt.toISOTime(), dt.toUTC().toFormat('HH:mm'))
  return dt.toUTC().toFormat("HH:mm");
};

const convert_time_of_excluded_slots = (list: TypeLunch[]): TypeLunch[] => {
  const new_list = list.map((item) => {
    if (item["startTime"] === "00:00" && item["endTime"] === "00:00") {
      return item;
    }
    return {
      ...item,
      startTime: convert_time_to_utc(item["startTime"]),
      endTime: convert_time_to_utc(item["endTime"]),
    };
  });
  return new_list;
};

export const ExcludedSlotsSelector = createSelector(
  // attention, ici on traduit le date en utc !
  (state: RootState) => state.lunch,
  (state: RootState) => state.embauche,
  (state: RootState) => state.debauche,
  (state: RootState) => state.weekendsVisible,
  (
    lunch: TypeLunch,
    embauche: string,
    debauche: string,
    weekendsVisible: boolean
  ) => {
    const ES = [
      {
        name: "non travaillé",
        unavailabilty_rate: 1,
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Monday, Tuesday, Wednesday
        startTime: debauche, // 8am
        endTime: embauche, // 6pm
      },
      lunch,
    ];
    if (!weekendsVisible) {
      const weekend_slot = {
        on: true,
        name: "weekend",
        unavailabilty_rate: 1,
        daysOfWeek: [5, 6],
        startTime: "00:00",
        endTime: "00:00",
      } as TypeLunch;
      return convert_time_of_excluded_slots([
        ...ES,
        weekend_slot,
      ] as TypeLunch[]);
    }
    return convert_time_of_excluded_slots(ES as TypeLunch[]);
  }
);
