import { Modal } from "react-bootstrap";

export const OnLeaveInvitedModal = ({
  footer,
  modalOn,
  handleClose,
}: {
  footer: JSX.Element;
  modalOn: boolean;
  handleClose: () => void;
}) => {
  return (
    <Modal
      backdrop="static"
      keyboard={true}
      show={modalOn}
      onHide={handleClose}
      centered
    >
      <Modal.Header closeButton>
        {" "}
        <h1>Merci !</h1>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "left" }}>
        <p style={{ margin: "5px" }}>
          Les autres participants vont être interrogés en fonction des éléments
          que vous venez d'entrez. Si tout se passe bien, l'organisateur de la
          réunion vous enverra l'invitation correspondant à un créneau
          disponible pour vous.
        </p>
        <p style={{ margin: "5px" }}>
          Vous serez recontacté si aucun créneau n'est compatible. En attendant,
          vous pouvez ajouter les options à votre agenda.
        </p>
        <p style={{ margin: "5px" }}>
          Et pour que ce soit encore plus simple, vous pourrez bientôt devenir
          client DaTime et ce que vous venez de faire sera automatique !
        </p>
      </Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
};
