import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from 'react-bootstrap/NavDropdown';

import { Link } from "react-router-dom";

import logo from "../favicon-128.png";

function NavbarInvited() {
  // utiliser le offcanva, c'est cool
  return (
    <>
      <Navbar
        bg="primary"
        variant="dark"
        expand="lg"
        style={{
          fontSize: "large",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Navbar.Brand style={{ alignContent: "center" }}>
          <img
            alt=""
            src={logo}
            // src="../datetime/favicon-128.png"
            width="70"
            height="70"
            className="d-inline-block align-center"
          />{" "}
          DaTime <br></br>
          Trouve pour vous des rendez-vous qui vous conviennent
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="container-fluid"></Nav>

          <Nav>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Nav.Link as={Link} to="https://datime.fr">
              {" "}
              En savoir plus sur DaTime
            </Nav.Link>
            {/* <Navbar.Text >
                            <SupabaseAuth></SupabaseAuth>
                        </Navbar.Text> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default NavbarInvited;
