type MailBodyType = {
  author: string;
  object: string;
  lien_dateTime: string;
  lien_deja_client: string;
};

export const mail_Datime = ({
  author,
  object,
  lien_dateTime,
  lien_deja_client,
}: MailBodyType) => {
  return (
    <div style={{ textAlign: "left", justifyContent: "left" }}>
      <p>Bonjour</p>
      <p>
        Organiser facilement la réunion pour nom : <strong>{object}</strong>{" "}
        avec DaTime.
      </p>
      <p>
        C'est la solution qu'a choisie {author} pour vous proposer des créneaux
        et vous permettre d'y réagir sans rien écrire en quelques clics. Trouver
        un créneau commun n'a jamais été aussi simple. Allez vite sur ce{" "}
        <a href={lien_dateTime}> lien sécurisé </a>. Cliquer sur les créneaux :
        vert c'est bon, orange pourquoi pas et rouge c'est non !
      </p>
      <p>
        {" "}
        En savoir plus sur <a href="https://datime.fr"> DaTime </a>
      </p>
    </div>
  );
};

// export const mail_Datime = ({
//   author,
//   object,
//   lien_dateTime,
//   lien_deja_client,
// }: MailBodyType) => {
//   return (
//     <div style={{ textAlign: "left", justifyContent: "left" }}>
//       <p>Bonjour</p>
//       <br />
//       <p>
//         Nous avons été chargé par {author} d'organiser avec vous une réunion
//         d'une heure pour nom : <strong>{object}</strong>. Plutôt que de vous
//         proposer des créneaux par mails et de vous obliger à rédiger un mail en
//         retour, DaTime vous propose un outil visuel qui vous permet de répondre
//         en quelques clics. En plus cela permet d'adapter les créneaux au fur et
//         à mesure sans noyer votre boite mail sous les mails à chaque fois que
//         quelqu'un répond !
//       </p>
//       <br />
//       <p>
//         {" "}
//         Et plus votre agenda et celui des autres membres de la réunion est
//         compliqué plus DaTime vous facilite la vie ! Allez tout de suite sur la
//         page confidentielle suivante :{" "}
//         <a href={lien_dateTime}> lien confidentiel </a>
//       </p>
//       <br />
//       <p>
//         {" "}
//         Avec DaTime, vous pourrez gratuitement proposer des créneaux,
//         synchronisez vos disponibilités et détailler par exemple, si vous
//         préférez que le rendez-vous soit le mardi ou un matin et notre
//         technologie AgendIA fera en sorte de trouver le créneau qui vous
//         arrange.
//       </p>
//       <br />
//       <p>
//         Si vous avez déjà un compte DaTime, il ne semble pas associé à cette
//         adresse mail. Vous pouvez associez différentes adresses à votre compte
//         si vous le souhaitez. Dans ce cas : cliquer sur{" "}
//         <a href={lien_deja_client}> ce lien </a>{" "}
//       </p>
//     </div>
//   );
// };
