import { supabase } from './supabaseClient';


export const DBrequestLinkUser = async (id: string) => {
    const { data, error } = await supabase
        .from('id_user')
        .select()
        .eq('id_of_link', id);

    if (error) { throw { error }; }
    return data
}


// si première visite 

export const ifFirstVisit = async (id:string) => {
    
    console.log('On est dans ifFirstVisit')
    // connextion anonyme
    const anonConnect = async () => {
        const { data: data_auth, error } = await supabase.auth.signInAnonymously({
            // options: {
            //     captchaToken: process.env.REACT_APP_HCAPTCHA_SITE_KEY,
            // }
        })
        console.log('dans data pour auth anon', data_auth)
        return data_auth
    }

    const createLink = async (id:string, userId:string) => {
        const { data, error } = await supabase
        .from('id_user')
        .insert({id_of_link: id, userId: userId})
    }
    anonConnect().then(
        data_auth => { 
            data_auth.user ? createLink(id, data_auth.user.id) : console.log('pas de user !! ')
            }
    )
    
}


    // on relit 



