export const name_of_availability = (availability: number) => {
  switch (availability) {
    case 1:
      return "Possibilité";
    case 0:
      return "Déclaré indisponible";
    default: // pour entre 0 et 1
      return "Créneau non préférentiel";
  }
};

export const title_of_proposition = (
  availability: number,
  name: string | undefined
) => {
  let new_title = name_of_availability(availability);
  if (name) {
    return new_title + " pour " + name;
  }
  return new_title;
};
