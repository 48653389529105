import { createSlice } from "@reduxjs/toolkit";
import { label_of_day_period } from "../data/utils";

type DayPeriodType = keyof typeof label_of_day_period;

const initialDaySlot: any = {};
for (const value in Object.values(label_of_day_period)) {
  initialDaySlot[value] = false;
}

type payloadType = {
  payload: {
    day: numberOfDay;
    period: DayPeriodType;
  };
};

const DaySlotSlice = createSlice({
  name: "DaySlots",
  initialState: {
    "0": initialDaySlot,
    "1": initialDaySlot,
    "2": initialDaySlot,
    "3": initialDaySlot,
    "4": initialDaySlot,
    "5": initialDaySlot,
    "6": initialDaySlot,
    TimesOfDay: label_of_day_period,
  },
  reducers: {
    toggleDaySlot: (dayslots, action: payloadType) => {
      let new_dayslots = dayslots;
      new_dayslots[action.payload.day][action.payload.period] =
        !new_dayslots[action.payload.day][action.payload.period];
      return new_dayslots;
    },
  },
});

const { actions, reducer } = DaySlotSlice;

export const { toggleDaySlot } = actions;
export default reducer;
