
import React, { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';

import { Icon1Circle, Icon2Circle, Icon3Circle } from 'react-bootstrap-icons';

const ReSchedule = () => {

    const [modalOn, setModalOn] = useState(false);

    const handleClose = () => setModalOn(false);

    return (
        <>
            <br /> 
            <Container>
                <Row>
                   <h3> Evaluer instantanément la possibilité de déplacer  </h3>   {/* // pour sauter des lignes */}
                   <p> DaTime me répondra en fonction des disponibilités collectées au moment de la validation du rendez-vous.
                    Elles ont pu changer depuis </p>
                   <Button> Demander à DaTime </Button>
                   <h1>  <br />  </h1> 
                </Row>
                <Row>
                    <h3> Sondez les participants pour deplacer si possible </h3>   {/* // pour sauter des lignes */}
                    <p> Procéder à la demande de deplacement. DaTime va resonder les participants pour vous. </p>
                   <Button> Organiser déplacement </Button>
                   <h1>  <br />  </h1> 
                </Row>
                <Row>
                    <h3> Je ne peux vraiment pas honorer la réunion </h3>   {/* // pour sauter des lignes */}
                    <p> DaTime va relancer les particpants pour retrouver un créneau. </p>
                   <Button> Annuler et déplacer le rendez-vous </Button>
                   <h1>  <br />  </h1> 
                </Row>

                <Row>
                    <Col> <Button> Créer un compte DaTime pour ce rendez-vous et les suivants </Button></Col>
                    <Col><h2> <a href=''>Découvrez comment DaTime va simplifier votre vie</a>  </h2> </Col>
                    <h1>  <br />  </h1> 
                </Row>

            </Container>
        </>
    )
}

export default ReSchedule