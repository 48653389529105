import { TypeProposition } from "types/Propositions";

export const intialize_user_opinion = (
  prop: TypeProposition,
  id_user: string
): TypeProposition => {
  let all_opinion = prop["validated_by"].concat(
    prop["possible_by"],
    prop["rejected_by"]
  );
  console.log("intialize_user_opinion", prop);
  if (all_opinion.includes(id_user)) {
    return prop;
  }
  return { ...prop, validated_by: [...prop["validated_by"], id_user] };
};

export const shift_user_opinion = (
  id_user: string,
  proposition: TypeProposition
): [string[], string[], string[]] => {
  if (proposition["validated_by"].includes(id_user)) {
    const possible_by = [...proposition["possible_by"], id_user];
    return [
      proposition["validated_by"].filter((item) => item !== id_user),
      possible_by,
      proposition["rejected_by"],
    ];
  } else if (proposition["possible_by"].includes(id_user)) {
    const rejected_by = [...proposition["rejected_by"], id_user];
    return [
      proposition["validated_by"],
      proposition["possible_by"].filter((item) => item !== id_user),
      rejected_by,
    ];
  } else if (proposition["rejected_by"].includes(id_user)) {
    const validated_by = [...proposition["validated_by"], id_user];
    return [
      validated_by,
      proposition["possible_by"],
      proposition["rejected_by"].filter((item) => item !== id_user),
    ];
  } else {
    return [[], [], []];
  }
};
